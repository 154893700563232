@import 'mixins';
@import 'breakpoints';

.app-listening-false-wrapper {
  @include absolute-top-right;
  @include set-width-height(200px);
  @include breakPointsSetWidthHeight(175px, 150px, 125px, 100px);
}

.app-listening-true-wrapper {
  @include absolute-top-right;
  @include set-width-height(250px);
  @include breakPointsSetWidthHeight(210px, 170px, 135px, 100px);
  pointer-events: none;
}

.app-listening-image {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0 auto auto;
  @include full-width-height;
  pointer-events: none;
}

.app-listening-image-from-left {
  animation: 0.5s comesFromLeft;
}

@keyframes comesFromLeft {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

@include makeFadeInOut(fadeInOut, 200px);
@include makeFadeInOut(fadeInOutLargeTablet, 175px);
@include makeFadeInOut(fadeInOutSmallTablet, 150px);
@include makeFadeInOut(fadeInOutLargePhone, 125px);
@include makeFadeInOut(fadeInOutSmallPhone, 100px);

.app-speaking-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0 auto auto;
  animation: 1s fadeInOut infinite;

  @media (max-width: $largeTablet) {
    animation: 1s fadeInOutLargeTablet infinite;
  }

  @media (max-width: $smallTablet) {
    animation: 1s fadeInOutSmallTablet infinite;
  }

  @media (max-width: $largePhone) {
    animation: 1s fadeInOutLargePhone infinite;
  }

  @media (max-width: $smallPhone) {
    animation: 1s fadeInOutSmallPhone infinite;
  }
}
