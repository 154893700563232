@import 'mixins';

.reading-page {
  @include set-background('../../images/backgrounds/reading/reading.webp');
}

// if new pages are being added in reading, adding them here might be enough
$readingPageNames: 'words', 'sentences', 'stories';

@each $readingPageName in $readingPageNames {
  $readingPageBackground: '../../images/backgrounds/reading/#{$readingPageName}-reading.webp';

  .#{$readingPageName}-reading-page {
    @include set-background($readingPageBackground);
  }

  .easy-#{$readingPageName}-reading-page,
  .medium-#{$readingPageName}-reading-page,
  .hard-#{$readingPageName}-reading-page {
    @include question-answer-game-page;
    @include set-background($readingPageBackground);
  }
}
