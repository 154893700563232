/* WARNING: Because of the usage of animation via react-animations 
and the fact that they never work the same from an animation to another 
(custom unknown css) and also because I nest them or chain them, 
this file is a shit fest of crazy custom css! Do not use this elsewhere */

@import 'mixins';
@import 'breakpoints';

@mixin learn-maths {
  display: flex;
  @include full-width-height;
  align-items: center;
  position: relative;
}

.learn-mathematics-page {
  @include flex-space-around;
  flex-direction: column;
}

.learn-mathematics-animation-container {
  @include flex-center;
  @include set-width-height(500px);
  @include breakPointsSetWidthHeight(450px, 400px, 350px, 300px, 200px);

  .left-hand {
    transform: scaleX(-1);
  }

  .left-hand,
  .right-hand {
    display: flex;
    width: 100%;
  }
}

.learn-horizontal-numbers {
  flex-direction: row !important;

  img {
    max-height: 150px;
  }

  .plus-sign,
  .equal-sign {
    max-width: 100px;
  }
}

.learn-flex-center-center {
  @include learn-maths;
  justify-content: center;
}

.learn-flex-column-space-between {
  @include learn-maths;
  justify-content: space-between;
  flex-direction: column;
}

.learn-flex-column-space-around {
  @include learn-maths;
  justify-content: space-around;
}

.learn-math-equation-from-the-right-container {
  @include learn-maths;
  flex-direction: column;
  align-items: flex-end;
}

@mixin math-line-small-mixin {
  position: relative;
  display: flex;
  padding-right: 25%;
}

.math-line-small-fifth {
  @include math-line-small-mixin;
  height: 20%;
}

.math-line-small-six {
  @include math-line-small-mixin;
  height: 16.5%;
}

.math-line-small-eight {
  @include math-line-small-mixin;
  height: 12.5%;
}

.math-line-small-ten {
  @include math-line-small-mixin;
  height: 10%;
}

.math-line-line {
  position: absolute;
  height: 50%;
  right: 0;
  top: 35%;
}

.math-line-line-nine {
  position: absolute;
  height: 100%;
  right: 50%;
}

.math-line-line-ten {
  position: absolute;
  width: 200%;
  right: 25%;
}

.math-line-line-eleven {
  position: absolute;
  right: 75%;
  height: 85%;
}

.math-line-line-twelve {
  position: absolute;
  height: 75%;
  right: 60%;
}

.math-line-line-thirteen {
  position: absolute;
  width: 225%;
  right: 60%;
}

.math-line-line-fourteen {
  position: absolute;
  right: 75%;
  height: 95%;
}

@mixin bar {
  width: 25px;
  @include breakPointsWidth(22px, 20px, 18px, 15px, 12px);
  margin: 20px;
  @include breakPointsMargin(18px, 16px, 14px, 12px, 10px);
  border-radius: 25px;
}

.math-bar {
  @include bar;
  background: black;
}

.math-bars-container {
  display: flex;
  height: 100px;
  @include breakPointsHeight(88px, 80px, 72px, 60px, 48px);
}

.learn-maths-math-sign {
  display: flex;
  @include set-width-height(100px);
  @include breakPointsSetWidthHeight(88px, 80px, 72px, 60px, 48px);

  img {
    @include full-width-height;
  }
}

.small-number {
  max-height: 100%;
  position: relative;
}

.small-number-with-margins {
  max-height: 100%;
  position: relative;
  margin: 0 15px;
  @include breakPointsMargin(0 12px, 0 10px, 0 8px, 0 6px, 0 5px);
}

.small-number-with-container {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  width: 75px;
  @include breakPointsWidth(67px, 58px, 49px, 40px, 29px);

  img {
    height: 100%;
  }
}

.small-number-with-small-container {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  width: 70px;
  @include breakPointsWidth(66px, 57px, 47px, 38px, 26px);

  img {
    height: 100%;
  }
}

.small-number-with-margins-2 {
  height: 70%;
  right: 20%;
  top: 25%;
}

.small-number-with-margins-3 {
  height: 85%;
  right: 75%;
}

.small-number-with-margins-4 {
  height: 50%;
  top: 25%;
}

.very-small-number {
  max-height: 20%;
}

.very-very-small-number {
  max-height: 15%;
}

.very-small-maths-sign {
  max-height: 10%;
}

.very-very-small-maths-sign {
  max-height: 7%;
}

@mixin superSmall() {
  position: absolute;
  max-height: 50%;
  bottom: 100%;
}

.super-small-number {
  @include superSmall;
}

.super-small-sign {
  @include superSmall;
  right: 100%;
}

.top-line {
  bottom: 300%;
}

.medium-number {
  max-height: 150px;
  position: relative;
}

.medium-addition-plus {
  left: -150%;
}

.medium-addition-plus-one {
  left: -100%;
}

.medium-addition-plus-two {
  bottom: 25%;
  left: 140%;
}

.medium-addition-plus-one-two {
  bottom: 25%;
  left: 158%;
}

.medium-addition-plus-three {
  left: 10%;
  bottom: 25%;
}

.medium-addition-plus-one-three {
  left: 30%;
  bottom: 25%;
}

.math-line-line-two {
  position: absolute;
  right: 0;
  top: 15%;
  height: 100%;
}

.math-line-line-three {
  position: absolute;
  height: 33%;
  right: 33%;
  top: 15%;
}

.math-line-line-three-scale {
  position: absolute;
  height: 40%;
  right: 50%;
  top: 20%;
  transform: scaleX(-1);
}

.math-line-line-five {
  position: absolute;
  height: 70%;
  right: 40%;
}

.math-line-line-eight {
  position: absolute;
  height: 65%;
  right: 65%;
}

.right-20 {
  right: 20%;
}

.right-13 {
  right: 13%;
}

.medium-subtraction-plus {
  position: absolute;
  width: 40%;
  bottom: 55%;
  left: -80%;
}

.medium-subtraction-plus-one {
  position: absolute;
  width: 25%;
  bottom: 55%;
  left: -40%;
}

.medium-subtraction-plus-zero {
  position: absolute;
  width: 30%;
  bottom: 58%;
  left: -13%;
}

.medium-subtraction-minus {
  position: absolute;
  bottom: 13%;
  left: -156%;
  width: 33%;
}

.medium-subtraction-minus-one {
  position: absolute;
  width: 30%;
  bottom: 5%;
  left: -122%;
}

.medium-subtraction-minus-zero {
  position: absolute;
  width: 33%;
  bottom: 64%;
  left: -46%;
}

.medium-subtraction-minus-two {
  width: 33%;
  bottom: 30%;
  left: -35%;
}

.magic-hat {
  left: 0;
  bottom: 0;
}

.magic-wand {
  top: 0;
  right: 0;
}

.magic-bunny {
  top: 0;
  left: 12%;
}

.magic-hat-sparkle {
  top: 0;
  left: 0;
}

.medium-subtraction-opacity-four {
  position: absolute;
  max-height: 100%;
  left: -10%;
  opacity: 0.3;
}

.left-minus-12 {
  position: absolute;
  height: 100%;
  left: -12%;
}

.medium-subtraction-minus-two-nineteen {
  bottom: 30%;
  left: -51%;
  width: 37%;
}

.bottom-25 {
  bottom: -25%;
}

// arrows

.arrow {
  position: absolute;
  z-index: 1000;
}

.arrow-one {
  left: -70%;
  bottom: 75%;
}

.arrow-two {
  bottom: 75%;
  right: 120%;
  width: 150%;
}

.arrow-two-move {
  position: absolute;
  height: 150%;
  left: 14%;
  bottom: 72%;
}

.arrow-three {
  right: 119%;
  bottom: 0;
  width: 100%;
  height: 300%;
}

.arrow-three-move {
  width: 95%;
  position: absolute;
  right: 16%;
  bottom: 5%;
}

.arrow-four {
  bottom: 125%;
  height: 150%;
}

.arrow-four-two {
  left: -70%;
}

.arrow-six {
  left: -70%;
  bottom: 0;
  width: 100%;
  height: 300%;
}

.arrow-six-move {
  width: 75%;
  position: absolute;
  right: 26%;
  bottom: 5%;
}

.arrow-seven {
  left: -140%;
  bottom: 0;
  width: 100%;
  height: 300%;
}

.arrow-seven-move {
  width: 100%;
  position: absolute;
  right: 16%;
  bottom: 0;
}

.arrow-eight {
  right: 123%;
  bottom: 145%;
  width: 100%;
  height: 300%;
}

.arrow-eight-move {
  width: 93%;
  position: absolute;
  right: 17%;
  bottom: 3%;
}

.arrow-nine {
  bottom: 0;
  right: 218%;
  width: 100%;
  height: 300%;
}

.arrow-nine-move {
  width: 90%;
  position: absolute;
  right: -10%;
  bottom: 10%;
}

.arrow-ten {
  bottom: 145%;
  right: 175%;
  @include full-width-height;
}

.arrow-ten-move {
  width: 100%;
  position: absolute;
  left: 171%;
  bottom: -130%;
}

.arrow-thirteen {
  bottom: 150%;
  right: -40%;
}

.arrow-thirteen-two {
  bottom: 150%;
  right: 40%;
}

.arrow-fourteen {
  bottom: 58%;
}

.arrow-fifteen {
  bottom: 0;
  right: 80%;
}

.arrow-sixteen {
  width: 100%;
  bottom: 30%;
  right: 45%;
}

.arrow-seventeen {
  bottom: 75%;
  left: -166%;
  height: 210%;
  position: absolute;
}

.arrow-eighteen {
  height: 250%;
  bottom: 53%;
  right: 112%;
}

.arrow-nineteen {
  bottom: 45%;
  right: 205%;
}

.arrow-twenty {
  height: 175%;
  bottom: 116%;
  right: 115%;
}

.arrow-twenty-one {
  height: 135%;
  bottom: -25%;
  right: 170%;
}

.arrow-twenty-two {
  height: 150%;
  bottom: 133%;
  right: 200%;
}

.arrow-twenty-three {
  height: 160%;
  top: -263%;
  right: 158%;
}

.arrow-twenty-four {
  bottom: 150%;
}

.arrow-twenty-five {
  bottom: 140%;
  height: 120%;
  left: 70%;
}

.arrow-twenty-six {
  bottom: 10%;
  height: 225%;
  left: 45%;
}

.arrow-twenty-seven {
  bottom: 78%;
  height: 225%;
  left: 26%;
}

.arrow-twenty-eight {
  bottom: 10%;
  height: 135%;
  left: 33.5%;
}

.arrow-twenty-nine {
  bottom: 100%;
  height: 210%;
  left: 20%;
}

.arrow-thirty {
  bottom: 32%;
  height: 275%;
  left: 31%;
}

.arrow-thirty-one {
  bottom: 5%;
  height: 200%;
  left: 18%;
}

.arrow-thirty-two {
  bottom: 10%;
  height: 350%;
  left: -10%;
}

.arrow-thirty-three {
  bottom: -95%;
  height: 200%;
  left: 32%;
}

.arrow-thirty-four {
  bottom: -45%;
  height: 350%;
  left: 10%;
}

.arrow-thirty-five {
  bottom: -90%;
  height: 275%;
  left: 9%;
}

.arrow-thirty-six {
  bottom: 90%;
  height: 210%;
  left: -5%;
}

.arrow-thirty-seven {
  bottom: -80%;
  height: 400%;
  left: -5%;
}

.arrow-thirty-eight {
  bottom: -85%;
  height: 350%;
  left: -5%;
}

.arrow-thirty-nine {
  bottom: 10%;
  height: 125%;
  left: 58%;
}

.arrow-forty {
  bottom: 10%;
  height: 150%;
  left: 46%;
}

.arrow-forty-one {
  bottom: 4%;
  height: 150%;
  left: 25%;
}

.arrow-forty-two {
  bottom: 8%;
  height: 100%;
  left: 8%;
}

.arrow-forty-three {
  bottom: 120%;
  height: 175%;
  left: 0;
}

.arrow-forty-four {
  bottom: -90%;
  height: 275%;
  left: 9%;
}

.arrow-forty-five {
  bottom: 175%;
  height: 160%;
  right: 260%;
}

.arrow-forty-six {
  bottom: -100%;
  height: 100%;
}

.arrow-forty-seven {
  top: 150%;
  width: 60%;
  left: 35%;
}

.arrow-forty-eight {
  bottom: -100%;
  right: 137%;
}

.arrow-forty-nine {
  bottom: -120%;
  right: -45%;
}

.arrow-fifty {
  bottom: -75%;
  left: -85%;
  height: 375%;
}

.arrow-fifty-one {
  bottom: 140%;
  height: 250%;
  right: 260%;
}

.arrow-fifty-two {
  bottom: -155%;
  height: 150%;
  left: -5%;
}

.arrow-fifty-three {
  top: -110%;
  height: 210%;
  right: 138%;
}

.arrow-fifty-four {
  top: -120%;
  width: 70%;
  right: 125%;
}

.arrow-fifty-five {
  top: -180%;
  width: 115%;
  right: -25%;
}

.arrow-fifty-six {
  bottom: -95%;
  width: 90%;
  right: 68%;
}

.arrow-fifty-seven {
  bottom: -200%;
  width: 95%;
  right: 60%;
}

.arrow-fifty-eight {
  bottom: -175%;
  width: 180%;
  right: 130%;
}

.small-number-thirteen-plus {
  position: absolute;
  height: 40%;
  left: -13%;
  bottom: 15%;
}

.small-number-thirteen-one {
  position: absolute;
  height: 50%;
  left: -6%;
  bottom: 15%;
}

.beginner-subtraction-cross-one {
  position: absolute;
  left: 50%;
  top: 2%;
}

.beginner-subtraction-cross-two {
  position: absolute;
  left: 66%;
  top: 23%;
}

.math-bar-one,
.math-bar-one-bd,
.math-bar-one-three,
.math-bar-plus-two,
.math-bar-one-ten {
  transform: rotate(-9deg);
  border-radius: 50px 50px 18px 18px / 150px;
  height: 90%;
  margin-top: 2%;
}

.math-bar-two,
.math-bar-two-bd,
.math-bar-two-three,
.math-bar-two-ten {
  transform: rotate(-6deg);
  border-radius: 10px 32px 5px 40px/106px;
  height: 70%;
  margin-top: 8%;
  padding-right: 1%;
}

.math-bar-three,
.math-bar-three-bd,
.math-bar-three-three,
.math-bar-three-ten {
  transform: rotate(4deg);
  border-radius: 50px 5px 70px 18px / 140px;
  height: 100%;
}

.math-bar-four,
.math-bar-four-bd,
.math-bar-four-three,
.math-bar-plus-one,
.math-bar-four-ten {
  transform: rotate(-12deg);
  border-radius: 33px 3px 45px 23px;
  height: 93%;
  margin-top: 3%;
  padding-right: 1%;
}

.math-bar-five,
.math-bar-five-bd,
.math-bar-five-three,
.math-bar-five-ten {
  transform: rotate(-9deg);
  border-radius: 33px 3px 45px 23px;
  height: 53%;
  padding-right: 1%;
}

.math-bar-six,
.math-bar-six-bd,
.math-bar-six-three,
.math-bar-six-ten {
  transform: rotate(2deg);
  border-radius: 22px 34px 30px 1px / 140px;
  height: 77%;
  margin-top: 11%;
}

.learn-medium-additions-math-sign {
  position: absolute;
  transform: rotate(-91deg);
}

.math-bar-seven,
.math-bar-seven-bd,
.math-bar-seven-medium-subtraction {
  transform: rotate(3deg);
  border-radius: 12px 34px 30px 19px / 83px;
  height: 83%;
  margin-top: 3%;
}

.math-bar-seven-bd {
  margin-top: auto;
}

.math-bar-eight,
.math-bar-eight-medium-subtraction {
  transform: rotate(6deg);
  border-radius: 32px 44px 20px 39px/83px;
  height: 71%;
  margin-top: 6%;
}

.math-bar-nine {
  transform: rotate(1deg);
  border-radius: 2px 23px 10px 13px/83px;
  height: 61%;
  margin-top: 10%;
}

.math-bar-ten {
  transform: rotate(9deg);
  border-radius: 33px 13px 25px 33px;
  height: 93%;
  padding-right: 1%;
}

.math-bar-eleven {
  transform: rotate(-2deg);
  border-radius: 15px 44px 10px 39px/83px;
  height: 74%;
  margin-top: 2%;
}

.math-bar-twelve {
  transform: rotate(2deg);
  border-radius: 50px 48px 28px 39px/193px;
  height: 98%;
  margin-top: -1%;
}

.math-bar-thirteen {
  transform: rotate(7deg);
  border-radius: 23px 48px 72px 39px/193px;
  height: 76%;
  margin-top: 0;
}

.learn-easy-six-one {
  width: 65%;
}

@mixin drawBarAnim($delay) {
  background: linear-gradient(to bottom, black 50%, transparent 0);
  background-size: 100% 200%;
  background-position: bottom;
  visibility: hidden;
  animation: drawDown 0.25s $delay forwards;
}

@keyframes drawDown {
  100% {
    background-position: top;
    visibility: visible;
  }
}

.math-bar-one-cross,
.math-bar-one-cross-seven {
  position: absolute;
  transform: rotate(-139deg);
  border-radius: 12px 34px 12px / 67px;
  height: 85%;
  top: -7%;
}

.math-bar-two-cross,
.math-bar-two-cross-seven,
.math-bar-two-three-cross {
  position: absolute;
  transform: rotate(-145deg);
  border-radius: 12px 22px 42px / 167px;
  height: 82%;
  left: 17%;
  top: 1%;
}

.math-bar-two-cross-seven,
.math-bar-two-three-cross {
  left: 50%;
  top: -17%;
}

.math-bar-three-cross,
.math-bar-three-cross-seven,
.math-bar-three-three-cross {
  position: absolute;
  transform: rotate(-120deg);
  border-radius: 22px 22px 22px / 97px;
  height: 86%;
  left: 34%;
  top: 3%;
}

.math-bar-three-cross-seven,
.math-bar-three-three-cross {
  left: 2%;
}

.math-bar-four-cross-seven,
.math-bar-four-cross {
  position: absolute;
  transform: rotate(-151deg);
  border-radius: 7px 12px 12px / 127px;
  height: 92%;
  left: 50%;
  top: -7%;
}

.math-bar-five-cross,
.math-bar-five-cross-seven,
.math-bar-five-three-cross {
  position: absolute;
  transform: rotate(-120deg);
  border-radius: 17px 12px 42px / 37px;
  height: 70%;
  left: 67%;
  top: -9%;
}

.math-bar-five-cross-seven,
.math-bar-five-three-cross {
  left: 2%;
}

.math-bar-six-cross,
.math-bar-six-cross-seven,
.math-bar-six-three-cross {
  position: absolute;
  transform: rotate(-126deg);
  border-radius: 27px 22px 2px / 77px;
  height: 90%;
  left: 84%;
  top: 13%;
}

.math-bar-six-cross-seven,
.math-bar-six-three-cross {
  left: 51%;
  top: -13%;
}

.math-bar-seven-cross {
  position: absolute;
  transform: rotate(-108deg);
  border-radius: 27px 22px 2px / 77px;
  height: 84%;
  left: 0%;
  bottom: -17%;
}

.math-bar-eight-cross {
  position: absolute;
  transform: rotate(-126deg);
  border-radius: 17px 2px 25px / 177px;
  height: 74%;
  left: 15%;
  bottom: -19%;
}

.math-bar-nine-cross {
  position: absolute;
  transform: rotate(-104deg);
  border-radius: 47px 32px 15px / 127px;
  height: 75%;
  left: 28%;
  bottom: -27%;
}

.math-bar-ten-cross {
  position: absolute;
  transform: rotate(-121deg);
  border-radius: 37px 12px 45px / 57px;
  height: 87%;
  left: 44%;
  bottom: -25%;
}

.math-bar-eleven-cross {
  position: absolute;
  transform: rotate(-142deg);
  border-radius: 17px 28px 35px / 87px;
  height: 87%;
  left: 58%;
  bottom: -5%;
}

.math-bar-twelve-cross {
  position: absolute;
  transform: rotate(-107deg);
  border-radius: 7px 18px 25px / 187px;
  height: 73%;
  left: 72%;
  bottom: 5%;
}

.math-bar-thirteen-cross {
  position: absolute;
  transform: rotate(-122deg);
  border-radius: 37px 28px 35px / 167px;
  height: 89%;
  left: 86%;
  bottom: 0;
}

.math-bar-eight-cross-medium-subtraction {
  position: absolute;
  transform: rotate(-122deg);
  border-radius: 37px 28px 35px / 167px;
  height: 19%;
  left: 89%;
}

.math-bar-seven-cross-medium-subtraction {
  position: absolute;
  transform: rotate(-107deg);
  border-radius: 7px 18px 25px / 187px;
  height: 13%;
  left: 77%;
}

.math-bar-six-cross-medium-subtraction {
  position: absolute;
  transform: rotate(-99deg);
  border-radius: 17px 28px 35px / 87px;
  height: 17%;
  left: 65%;
  top: 6%;
}

.draw-bar-anim-one {
  @include drawBarAnim(0s);
}

.draw-bar-anim-seven,
.draw-bar-anim-one-cross {
  @include drawBarAnim(0.2s);
}

.draw-bar-anim-eight-cross-medium-subtraction {
  @include drawBarAnim(0.4s);
}

.draw-bar-anim-two {
  @include drawBarAnim(0.6s);
}

.draw-bar-anim-eight,
.draw-bar-anim-two-cross,
.draw-bar-anim-two-three-cross {
  @include drawBarAnim(0.8s);
}

.draw-bar-anim-seven-cross-medium-subtraction,
.draw-bar-anim-one-bd {
  @include drawBarAnim(1s);
}

.draw-bar-anim-three,
.draw-bar-anim-one-three,
.draw-bar-anim-three-three,
.draw-bar-anim-five-three {
  @include drawBarAnim(1.2s);
}

.draw-bar-anim-nine,
.draw-bar-anim-three-cross,
.draw-bar-anim-one-cross-seven,
.draw-bar-anim-three-three-cross,
.draw-bar-anim-two-bd {
  @include drawBarAnim(1.4s);
}

.draw-bar-anim-six-cross-medium-subtraction {
  @include drawBarAnim(1.6s);
}

.draw-bar-anim-four,
.draw-bar-anim-two-three,
.draw-bar-anim-four-three,
.draw-bar-anim-six-three,
.draw-bar-anim-four-cross,
.draw-bar-anim-three-bd {
  @include drawBarAnim(1.8s);
}

.draw-bar-anim-two-cross-seven,
.draw-bar-anim-ten {
  @include drawBarAnim(2s);
}

.draw-bar-anim-four-bd {
  @include drawBarAnim(2.2s);
}

.draw-bar-anim-five,
.draw-bar-anim-five-cross,
.draw-bar-anim-five-three-cross {
  @include drawBarAnim(2.4s);
}

.draw-bar-anim-three-cross-seven,
.draw-bar-anim-eleven,
.draw-bar-anim-five-bd {
  @include drawBarAnim(2.6s);
}

.draw-bar-anim-six,
.draw-bar-anim-six-cross,
.draw-bar-anim-six-three-cross,
.draw-bar-anim-six-bd {
  @include drawBarAnim(3s);
}

.draw-bar-anim-four-cross-seven,
.draw-bar-anim-twelve,
.draw-bar-anim-one-ten {
  @include drawBarAnim(3.2s);
}

.draw-bar-anim-plus-one,
.draw-bar-anim-seven-bd {
  @include drawBarAnim(3.4s);
}

.draw-bar-anim-seven-cross,
.draw-bar-anim-seven-medium-subtraction {
  @include drawBarAnim(3.6s);
}

.draw-bar-anim-two-ten {
  @include drawBarAnim(3.7s);
}

.draw-bar-anim-plus-two,
.draw-bar-anim-thirteen {
  @include drawBarAnim(3.8s);
}

.draw-bar-anim-eight-medium-subtraction,
.draw-bar-anim-three-ten,
.draw-bar-anim-eight-cross {
  @include drawBarAnim(4.2s);
}

.draw-bar-anim-six-cross-seven {
  @include drawBarAnim(4.4s);
}

.draw-bar-anim-four-ten {
  @include drawBarAnim(4.7s);
}

.draw-bar-anim-nine-cross {
  @include drawBarAnim(4.8s);
}

.draw-bar-anim-five-ten {
  @include drawBarAnim(5.2s);
}

.draw-bar-anim-ten-cross {
  @include drawBarAnim(5.4s);
}

.draw-bar-anim-six-ten {
  @include drawBarAnim(5.7s);
}

.draw-bar-anim-eleven-cross {
  @include drawBarAnim(6s);
}

.draw-bar-anim-twelve-cross {
  @include drawBarAnim(6.6s);
}

.draw-bar-anim-thirteen-cross {
  @include drawBarAnim(7.2s);
}

// for some strange reasons, this one does not follow the other times
.draw-bar-anim-five-cross-seven {
  @include drawBarAnim(7.2s);
}

.flexible-image {
  width: 100%;
  @include breakPointsWidth(85%, 70%, 55%, 40%, 30%);
}

.width-80 {
  width: 80%;
}

.math-circle {
  position: absolute;
  width: 35%;

  path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.math-circle-one {
  top: -5%;
  left: -18%;

  path {
    animation: dash 0.5s linear 2s forwards;
  }
}

.math-circle-two {
  top: -1%;
  left: -2%;

  path {
    animation: dash 0.5s linear 2.5s forwards;
  }
}

.math-circle-three {
  top: -3%;
  left: 11%;

  path {
    animation: dash 0.5s linear 3s forwards;
  }
}

.math-circle-four {
  width: 31%;
  top: -3%;
  left: 26%;

  path {
    animation: dash 0.5s linear 3.5s forwards;
  }
}

.math-circle-five {
  width: 33%;
  top: -4%;
  left: 46%;

  path {
    animation: dash 0.5s linear 4s forwards;
  }
}

@mixin widthFiftyAnimCircle {
  width: 50%;

  path {
    animation: dash 0.5s linear 0.5s forwards;
  }
}

.math-circle-six {
  top: -10%;
  left: -15%;
  @include widthFiftyAnimCircle;
}

@mixin mathCircleStatic {
  width: 50%;

  path {
    stroke-dashoffset: unset;
  }
}

.math-circle-six-static {
  top: -10%;
  left: -15%;
  @include mathCircleStatic;
}

.math-circle-seven {
  top: -50%;
  left: 18%;
  @include widthFiftyAnimCircle;
}

.math-circle-seven-static {
  top: -50%;
  left: 18%;
  @include mathCircleStatic;
}

.math-circle-eight {
  top: -10%;
  right: -1%;
  @include widthFiftyAnimCircle;
}

.math-circle-eight-static {
  top: -10%;
  right: -1%;
  @include mathCircleStatic;
}

.math-circle-nine {
  @include widthFiftyAnimCircle;
  top: -27%;
  left: -12%;
  width: 42%;
}

.math-circle-nine-static {
  @include mathCircleStatic;
  top: -27%;
  left: -12%;
  width: 42%;
}

.math-circle-ten {
  @include widthFiftyAnimCircle;
  top: -22%;
  left: 21%;
  width: 39%;
}

.math-circle-ten-static {
  @include mathCircleStatic;
  top: -22%;
  left: 21%;
  width: 39%;
}

.math-circle-eleven {
  @include widthFiftyAnimCircle;
  top: -25%;
  left: 45%;
  width: 38%;
}

.math-circle-eleven-static {
  @include mathCircleStatic;
  top: -25%;
  left: 45%;
  width: 38%;
}

.stopwatch {
  position: absolute;
  left: 21%;
  top: 55%;
  font-family: 'Orbitron';
  color: red;
  font-size: 45px;

  @media (max-width: $largePhone) {
    font-size: 40px;
  }

  @media (max-width: $smallPhone) {
    font-size: 35px;
  }

  @media (max-width: $verySmallPhone) {
    font-size: 22px;
  }
}

.learn-beginner-multiplications-frame-2 {
  max-height: none !important;
}

.learn-beginner-division-dog-one {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.learn-beginner-division-dog-two {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.learn-beginner-division-quotient-illustration {
  position: absolute;
  left: 0;
  top: 0;
}

.learn-beginner-division-remainder-illustration {
  position: absolute;
  right: 10%;
  bottom: 0;
}

.learn-easy-division-slide-nine-number {
  position: absolute;
  width: 45%;
  bottom: 10%;
  left: 28%;
  z-index: 1000;
}

.yellow-pulse {
  background: black;
  box-shadow: none;
  animation: 1.25s yellowPulse 2s infinite;
}

@keyframes yellowPulse {
  0% {
    background: black;
    box-shadow: none;
  }
  50% {
    background: gold;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  }
  100% {
    background: black;
    box-shadow: none;
  }
}

.learn-math-opacity-on {
  opacity: 1;
  animation: fadeMathsOut 0.4s forwards;
}

.learn-math-opacity-on-delayed {
  opacity: 1;
  animation: fadeMathsOut 0.4s forwards 2.5s;
}

@keyframes fadeMathsOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.08;
  }
}

.learn-math-opacity-still-on {
  opacity: 0.08;
}

.learn-math-opacity-off {
  opacity: 0.08;
  animation: fadeMathsIn 0.4s forwards;
}

.learn-math-opacity-off-delayed {
  opacity: 0.08;
  animation: fadeMathsIn 0.4s forwards 4.5s;
}

@keyframes fadeMathsIn {
  0% {
    opacity: 0.08;
  }
  100% {
    opacity: 1;
  }
}

.division-small-number {
  display: flex;
  height: 75px;
  @include breakPointsHeight(66px, 60px, 54px, 45px, 36px);
  width: 75px;
  @include breakPointsWidth(66px, 60px, 54px, 45px, 36px);
}

.division-medium-division-container-out {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @include set-width-height(500px);
  @include breakPointsSetWidthHeight(450px, 400px, 350px, 300px, 200px);
}

.division-medium-division-container-in {
  @include flex-space-between;
  width: 100%;
}

.division-medium-division-container-in-between {
  @include flex-space-around;
  width: 100%;
}

.small-division-number {
  width: 75px;
  @include breakPointsWidth(67px, 58px, 49px, 40px, 29px);
}

.smaller-division-number {
  width: 60px;
  @include breakPointsWidth(53.5px, 46.5px, 39px, 32px, 23px);
}

.super-small-division-number {
  width: 50px;
  @include breakPointsWidth(44.5px, 39px, 32.5px, 26.5px, 19px);
}

.division-comparison {
  display: flex;
  height: 150px;
  @include breakPointsHeight(132px, 120px, 108px, 90px, 72px);
}

.learn-medium-division-eleven {
  position: absolute;
  top: 18%;
}
