@import 'breakpoints';

.parents-info-button {
  position: absolute;
  top: 25px;
  left: 25px;
  min-width: 200px;
  text-decoration: none;
  text-align: center;
  color: mistyrose;
  background: darkred;
  padding: 10px;
  border: 5px solid red;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  transition: all 0.2s ease;
  z-index: 2;

  &:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.4);
    transform: translateY(-3px);
  }

  @include breakPointsFontSize(15px, 14px, 13px, 12px);
  @include breakPointsPadding(9px, 8px, 7px, 6px, 4px);

  @media (max-width: $largePhone) {
    min-width: auto;
    box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.4);
    border: 3px solid red;
  }
}

.parents-info-message {
  @include parentsInfoMessage('../../images/cloud.webp');
  font-size: 12px;
  width: 500px;
  cursor: default;

  @media (max-width: $largePhone) {
    background-size: 75%;
  }

  @media (max-width: $smallPhone) {
    background-size: 70%;
  }

  // @media (max-width: $verySmallPhone) {
  //   background-size: 50%;
  // }

  .clickable {
    color: blue;

    &:hover {
      text-decoration: underline;
    }
  }
}

.parents-info-explaination {
  margin-top: 6px;
}

.parents-info-faq-link {
  color: crimson;
  font-size: 16px;
  @include breakPointsFontSize(15px, 14px, 13px, 12px);

  &:hover {
    color: mediumvioletred;
    cursor: pointer;
  }
}

.parents-info-message_hidden {
  opacity: 0;
}

.parents-info-message_opened {
  animation: fadeIn 0.75s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.parents-info-message_closed {
  animation: fadeOut 0.75s;
  opacity: 0;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.letters-and-numbers-info-initial-state {
  @include parentsInfoMessage('../../images/wood-board.webp');
  width: 400px;
  left: 0;
  padding: 10px;
  font-size: 13px;
  text-shadow:
    -1px -1px rgba(255, 255, 255, 0.5),
    1px 1px rgba(255, 255, 255, 0.5);
  display: none;
  @include breakPointsFontSize(11.5px, 10px, 9px, 8px, 7px);
  @include breakPointsPadding(9px, 8px, 7px, 6px, 4px);
  @include breakPointsWidth(325px, 285px, 250px, 240px, 175px);
  @include breakPointsHeight(175px, 150px, 140px, 125px, 100px);
}

.letters-and-numbers-info-lines-wrapper {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 25px;

  @media (max-width: $largePhone) {
    margin-top: 20px;
  }

  @media (max-width: $verySmallPhone) {
    margin-top: 15px;
  }
}

.letters-and-numbers-info-title {
  font-size: 18px;
  @include breakPointsFontSize(16px, 14px, 12px, 10px, 8px);
}

.letters-and-numbers-info-section {
  text-align: left;
  width: 300px;
  @include breakPointsWidth(260px, 225px, 205px, 200px, 150px);
}

.letters-and-numbers-info-show {
  display: flex;
  animation: comesFromTop 1s;
  z-index: 999;
}

@keyframes comesFromTop {
  0% {
    top: -100%;
  }
  60% {
    top: 0;
  }
  70% {
    top: -1%;
  }
  80% {
    top: 0;
  }
  90% {
    top: -0.5%;
  }
  100% {
    top: 0;
  }
}

.letters-and-numbers-info-hide {
  animation: HidesToTop 2s forwards;
  display: flex;
}

@keyframes HidesToTop {
  0% {
    top: 1%;
  }
  10% {
    top: 0%;
  }
  100% {
    top: -100%;
  }
}
