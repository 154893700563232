@import 'mixins';
@import 'breakpoints';

.modal-mask,
.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-mask {
  @include full-width-height;
  z-index: 30;
  overflow-y: hidden;
  background: black;
  opacity: 0.85;
}

.modal-wrapper {
  @include flex-center;
  z-index: 50;
  pointer-events: none;
}

.modal-section-wrapper {
  background: floralwhite;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  // min-width: 400px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 8%;
}

.modal-section {
  position: relative;
}

.profile-page-save-modal-wrapper,
.profile-page-delete-modal-wrapper {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  border: 10px solid black;
  border-radius: 10px;

  h3 {
    padding-top: 50px !important;
  }
}

.profile-page-delete-modal-wrapper {
  h3 {
    color: crimson !important;
  }
}

.profile-page-modal-delete-button {
  width: max-content;
  margin: 5% !important;
  background: crimson !important;
  filter: brightness(0.2);

  @media (max-width: $largePhone) {
    margin: 10% !important;
  }

  @media (max-width: $smallPhone) {
    margin: 15% !important;
  }
}

.profile-page-save-modal-body {
  padding: 4% 8%;
  width: auto !important;
}

.profile-page-delete-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 14px;
  @include breakPointsFontSize(13px, 12.5px, 12px, 11px);
  padding: 4% 8%;
}

.modal-close-arrow {
  position: absolute;
  right: 6%;
  top: 5%;
  opacity: 0.8;
  cursor: pointer;
  filter: brightness(0.2);
  @include set-width-height(25px);
  @include breakPointsSetWidthHeight(24px, 22px, 20px, 16px);

  &:hover {
    opacity: 1;
  }
}
