@import 'mixins';

.words-page {
  @include set-background('../../images/backgrounds/words/words.webp');
}

// if new pages are being added in words, adding them here might be enough
$wordsPageNames: 'colors', 'animals', 'food', 'letters-and-numbers';

@each $wordsPageName in $wordsPageNames {
  $wordPageBackground: '../../images/backgrounds/words/#{$wordsPageName}.webp';

  .#{$wordsPageName}-page {
    @include set-background($wordPageBackground);
  }

  .learn-#{$wordsPageName},
  .play-#{$wordsPageName}-page-page {
    @include question-answer-game-page;
    @include set-background($wordPageBackground);
  }
}

.other-words-words-page {
  @include question-answer-game-page;
  @include set-background('../../images/backgrounds/words/other-words.webp');
}
