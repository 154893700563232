@import 'mixins';
@import 'breakpoints';
// @import 'footer';
@import 'navigation';
@import 'form';
@import 'words';
@import 'mathematics';
@import 'geography';
@import 'reading';
@import 'customColorClasses';
@import 'speechButton';
@import 'appListeningSpeakingVisual';
@import 'questionsPage';
@import 'showAnswerWrapper';
@import 'contactPage';
@import 'termsPages';
@import 'signUpSignInPage';
@import 'aboutPage';
@import 'supportPage';
@import 'temporarilyUnlockPage';
@import 'parentsInfo';
@import 'profilePage';
@import 'imageChoiceButton';
@import 'questionAndLearnDisplay';
@import 'wrongAnswersCrosses';
@import 'learnMathematics';
@import 'learnCircle';
@import 'modal';

html,
body {
  position: relative;
  @include full-width-height;
}

body {
  background: snow;
}

#root {
  @include flex-center;
  height: inherit;
}

.language-choice-wrapper,
.home-wrapper,
.top-navigation {
  @include flex-center;
  flex-direction: column;
}

.navigation-box {
  background: floralwhite;
  padding: 20px;
  margin: 50px;
  border: 10px solid black;
  @include breakPointsBorderWidth(9px, 8px, 7px, 6px, 5px);
  border-radius: 40px;
  @include breakPointsFontSize(15px, 14px, 13px, 12px);
  @include breakPointsMargin(25px, 20px, 15px, 10px);
  scroll-behavior: smooth;
  overflow-x: hidden;

  h3 {
    @include fancy-title-style(36px, wheat, lightsalmon, black, 1, 1);
    @include breakPointsFontSize(36px, 30px, 24px, 22px, 18px);
    padding: 35px 0 20px 80px;
    @include breakPointsPadding(
      32.5px 0 18px 80px,
      30px 0 16px 75px,
      28px 0 14px 70px,
      26px 0 12px 65px,
      24px 0 10px 60px
    );
    width: 200%;
  }

  button {
    margin: 0 10px;
    padding: 8px 18px;
    background: wheat;
    filter: drop-shadow(2px 1px 3px darkslategrey);

    @media (max-width: $smallPhone) {
      margin: 4px;
    }
  }
}

.language-page {
  .navigation-box {
    white-space: break-spaces;
  }
}

.settings-page {
  .navigation-box {
    max-width: 80%;
  }
}

.home-wrapper {
  @include set-background('../../images/backgrounds/home-wrapper.webp');
}

.detected-language {
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
}

.annyang-supported-state {
  max-width: 800px;
  font-weight: 600;
}

.mini-einstein-menu-picture {
  width: 400px;
  height: 75%;

  @media (max-width: $smallPhone) {
    width: 75%;
  }
}

.language-choice-general-instructions {
  @include flex-center;
  font-size: 13px;
  @include breakPointsFontSize(12px, 10px, 9px, 8px, 7.5px);
}

.home-box {
  @include flex-center;
  flex-direction: column;
  margin-top: 25px;

  @media (max-width: $smallPhone) {
    margin: auto 25px;
  }
}

.home-box-header {
  @include fancy-title-style();
  margin: -40px -8px 20px -50px;
  padding: 55px 0 15px 115px;
  width: 120%;

  .home-box-header-title {
    margin-bottom: 0;
  }

  .home-box-header-slogan {
    font-size: 18px;
    margin-top: 0;
    text-align: right;
    padding-right: 70px;
  }
}

.home-box-supported-state {
  margin: 10px 0;
}

.home-box-button,
.social-sign-button,
.navigation-commands button {
  position: relative;
  background: wheat;
  font-size: 13px;
  padding: 8px 18px;
  @include breakPointsFontSize(12.5px, 12px, 12px, 12px);
  @include breakPointsPadding(7px 16px, 6px 14px, 5px 12px, 4px 8px);
  margin-bottom: 12px;
  @include breakPointsMarginBottom(10px, 8px, 6px, 5px, 4px);

  &:not(.navigation-button-coming-soon-style) {
    opacity: 0.75;
    transition: all 0.25s linear;
    font-weight: bold;

    @media (max-width: $largePhone) {
      opacity: 1;
    }

    &:hover {
      cursor: pointer;
      @include translateYhoverButton(0.1, 0.2, -1px);
    }
  }
}

.home-last-updated {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 5px;
  font-size: 14px;
  @include breakPointsFontSize(12px, 11px, 10px, 9px);
  font-weight: 500;
}

.menu-button-icon-wrapper {
  @include flex-center;
  @include set-width-height(25px);
  @include breakPointsSetWidthHeight(22px, 20px, 16px, 12px, 10px);
  float: left;
  margin-right: 5px;
}

.menu-button-command-wrapper {
  line-height: 25px;
  @include breakPointsLineHeight(22px, 20px, 16px, 12px, 10px);
}

.navigation-button-coming-soon-topic-badge {
  @include customNavigationButtonIcon(deeppink);
  color: white;
}

.navigation-button-new-topic-badge {
  @include customNavigationButtonIcon(yellow);
}

.menu-button-icon {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  opacity: 1;
  transition: all 0.4s linear;
}

.menu-button-support-icon,
.menu-button-social-key-icon {
  opacity: 0;
  transition: all 0.4s linear;
  position: absolute;
}

.navigation-button-need-donation-style {
  @include navigationButtonNeedActionstyle();

  &:hover {
    .menu-button-support-icon {
      opacity: 1;
    }
  }
}

.navigation-button-need-sharing-style {
  @include navigationButtonNeedActionstyle();

  &:hover {
    .menu-button-social-key-icon {
      opacity: 1;
    }
  }
}

.navigation-button-coming-soon-style {
  background: lightgrey !important;
  cursor: not-allowed;

  .menu-button-icon {
    filter: grayscale(1);
  }
}

// Scroll bar
// WebKit Browsers (Chrome, Safari, Edge)
::-webkit-scrollbar {
  background-image: linear-gradient(wheat, snow, wheat);
  border-radius: 50px;
  border: 2px solid black;
}

::-webkit-scrollbar:vertical {
  height: 200px;
}

::-webkit-scrollbar:horizontal {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom right, lightsalmon, pink);
  border: 2px solid black;
  border-radius: 50px;
  height: 25%;
}

// Firefox
/* Apply styles only in Firefox */
@-moz-document url-prefix() {
  /* Firefox-specific scrollbar styling */
  * {
    scrollbar-color: lightsalmon wheat;
  }
}

.world-map {
  @include breakPointsStandardBorders();
  @include svgMapScaling();
}

.correct-answer-image {
  max-width: 500px;
  max-height: 500px;
  border-radius: 50px;
}

.clickable {
  cursor: pointer;
}

.water {
  fill: white;
}

.show-answer-introduction {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.show-answer-answer-shadow {
  position: relative;
  @include fancy-title-style(96px, none, black);
  text-align: center;
  margin: 25px 0 0;
}

.flex {
  display: flex;
}

.visibility-hidden {
  visibility: hidden;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.height-100 {
  height: 100%;
}

.top-0 {
  top: 0;
}

.height-50 {
  height: 50%;
}

.height-33 {
  height: 33%;
}

.width-50 {
  width: 50%;
}

.width-75 {
  width: 75%;
}

.width-100 {
  width: 100%;
}

.width-150 {
  width: 150%;
}

.max-100 {
  max-width: 100%;
  max-height: 100%;
}

.coming-soon {
  @include set-background('../../images/backgrounds/coming-soon.webp');
}

.none {
  display: none;
}

.page-not-found-page .navigation-box {
  min-height: 400px;
}

.page-not-found-image-wrapper {
  @include flex-center;
  margin: 0 auto;
  width: 35%;
  height: 60%;

  img {
    width: 100%;
  }
}

.read-out-loud-robotic-voice-notice {
  position: relative;
  background-image: linear-gradient(black, crimson);
  padding: 8px 48px 8px 16px;
  color: snow;
  position: absolute;
  bottom: 10px;
  border: 2px solid snow;
  border-radius: 5px;
  margin: 0 10px;
  font-size: 16px;
  font-weight: 500;
  @include breakPointsFontSize(14px, 12px, 11px, 10px);
  @include breakPointsPadding(
    7px 42px 7px 14px,
    6px 36px 6px 12px,
    5.5px 33px 5.5px 11px,
    5px 30px 5px 10px
  );
  min-width: 280px;
  left: 50%;
  transform: translateX(-50%);
}

.read-out-loud-robotic-voice-notice-cross {
  color: white;
  position: absolute;
  right: 5px;
  top: 3px;
  opacity: 0.8;
  cursor: pointer;

  @include set-width-height(25px);
  @include breakPointsSetWidthHeight(24px, 22px, 20px, 16px);

  &:hover {
    opacity: 1;
  }
}

.free-version-notice {
  position: absolute;
  @include set-background('../../images/yellow-tape.webp');
  @include flex-center;
  flex-direction: column;
  height: 60px;
  @include breakPointsHeight(50px, 45px, 40px, 35px);
  width: 600px;
  @include breakPointsWidth(500px, 450px, 400px, 350px);
  top: 120px;
  @include breakPointsTop(100px, 90px, 80px, 70px);
  left: -150px;
  @include breakPointsLeft(-125px, -112px, -100px, -87px);
  rotate: -45deg;
  filter: drop-shadow(30px 10px 50px white);
  pointer-events: none;

  .free-version-notice-title {
    font-size: 20px;
    @include breakPointsFontSize(16px, 14px, 12px, 11px);
    font-weight: 800;
  }

  .free-version-notice-message {
    font-size: 14px;
    @include breakPointsFontSize(12px, 10px, 9px, 8px);
    font-weight: 600;
    padding: 0 0 6px;
    @include breakPointsPadding(0 0 4px, 0 0 4px, 0 0 3px, 0 0 2px);
  }
}

// Fake buttons
.fake-button-one,
.fake-button-two,
.fake-button-three {
  margin: 20px;
  padding: 20px 40px;
  border-radius: 50px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  z-index: 999;

  border: 3px solid black;
}

.fake-button-one {
  background-image: linear-gradient(-180deg, green 0%, yellowgreen 100%);
  box-shadow:
    0 1rem 1.25rem 0 olive,
    0 -0.25rem 1.5rem darkgreen inset,
    0 0.75rem 0.5rem yellow inset,
    0 0.25rem 0.5rem 0 black inset;

  &:hover {
    background-image: linear-gradient(-180deg, mediumseagreen 0%, yellowgreen 100%);
  }
}

.fake-button-two {
  background-image: linear-gradient(-180deg, pink 0%, violet 100%);
  box-shadow:
    0 1rem 1.25rem 0 darkmagenta,
    0 -0.25rem 1.5rem deeppink inset,
    0 0.75rem 0.5rem pink inset,
    0 0.25rem 0.5rem 0 black inset;

  &:hover {
    background-image: linear-gradient(-180deg, pink 0%, snow 100%);
  }
}

.fake-button-three {
  background-image: linear-gradient(-180deg, orange 0%, crimson 100%);
  box-shadow:
    0 1rem 1.25rem 0 red,
    0 -0.25rem 1.5rem crimson inset,
    0 0.75rem 0.5rem orange inset,
    0 0.25rem 0.5rem 0 black inset;

  &:hover {
    background-image: linear-gradient(-180deg, orange 0%, goldenrod 100%);
  }
}

.warning-text {
  color: red;
  font-weight: 600;
}

.with-transition {
  opacity: 0;
  visibility: hidden;
  transition: all 400ms ease-in-out;
}

.with-transition-opened {
  opacity: 1;
  visibility: visible;
}

.navigate-link {
  @include link-behaviour;
}

.space-around {
  @include flex-space-around;
}

.space-between {
  @include flex-space-between;
}

.embedded-video {
  margin: 14px;
  height: auto;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video-wrapper {
  margin: 0 7.5%;
  @include breakPointsMargin(0 7.5%, 0 6%, 0 5%, 0);

  iframe {
    height: auto;
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}
