@import 'mixins';
@import 'breakpoints';

.contact-page {
  .contact-page-note {
    font-size: 14px;
    color: darkslategrey;
    margin: 25px 0;
  }

  .navigation-commands {
    text-align: end;
    margin-bottom: 25px;
  }
}

.contact-success-page,
.contact-error-page {
  .navigation-box {
    min-height: 400px;
  }
}

.contact-success-image-wrapper,
.contact-error-image-wrapper {
  @include flex-center;
  margin: 0 auto;

  img {
    width: 100%;
  }
}
.contact-success-image-wrapper {
  width: 65%;
  height: 50%;
}

.contact-error-image-wrapper {
  width: 35%;
  height: 60%;
}

.contact-form-column-one {
  margin-right: 25px;
}

.contact-form-column-two {
  width: 100%;

  > * {
    width: -webkit-fill-available;
  }

  flex: 3;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-self: stretch;

  textarea {
    height: 100%;
    font-family: inherit;
  }

  @media (max-width: $smallPhone) {
    margin-left: 0;

    textarea {
      height: 80px;
    }
  }
}
