@import 'mixins';
@import 'breakpoints';

.form {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.form-navigation-box {
  width: 600px;

  @media (max-width: $smallTablet) {
    width: auto;
    max-width: 600px;
  }
}

.form-fields {
  display: flex;
  align-items: flex-start;
  margin: 0 5px 25px 0;

  input,
  select,
  textarea {
    border: 2px solid black;
  }

  @media (max-width: $smallPhone) {
    flex-direction: column;
  }
}

.form-field-error {
  color: red;
  font-size: 14px;
  @include breakPointsFontSize(13px, 12px, 11px, 10px);
  font-weight: 500;
  margin-top: 4px;
}

.form-notification {
  margin-top: -7px;
  font-size: 14px;
  @include breakPointsFontSize(13px, 12.5px, 12px, 12px);
}

.form-notification-success,
.form-notification-warning,
.form-notification-error {
  position: absolute;
  top: 20px;
  padding: 5px;
  border: 8px solid black;
  border-radius: 10px;
  z-index: 999;
  color: floralwhite;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100px;
  font-weight: 800;
  font-style: italic;
  font-size: 14px;
  @include breakPointsFontSize(13px, 12px, 11px, 10px);
  padding: 8px 20px;
  @include breakPointsPadding(7px 16px, 6px 14px, 5px 12px, 4px 8px);
  text-shadow:
    -1px -1px rgba(0, 0, 0, 0.5),
    1px 1px rgba(0, 0, 0, 0.5);
}

.form-notification-success {
  background: forestgreen;
}

.form-notification-warning {
  background: darkorange;
}

.form-notification-error {
  background: crimson;
}

.form-link {
  color: salmon;
  opacity: 1;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
}

.form-column {
  width: 100%;
  flex: 1;

  > * {
    width: -webkit-fill-available;
  }
}

.form-input-title {
  font-weight: bold;
  padding: 15px 0 5px;
}

.form-md-input-field {
  font-size: 14px;
  padding: 6px 12px;
  @include breakPointsFontSize(13px, 12.5px, 12px, 12px);
  @include breakPointsPadding(5.5px 12px, 5.25px 11.5px, 5px 11px, 5px 11px);
}

.form-buttons {
  text-align: center;

  button {
    @include breakPointsFontSize(12.5px, 12px, 12px, 12px);
    @include breakPointsPadding(7px 16px, 6px 14px, 5px 12px, 4px 8px);
    opacity: 0.75;
    transition: all 0.25s linear;
    font-weight: bold;

    &.disabled {
      cursor: not-allowed;
    }

    &:not(.disabled) {
      &:hover {
        cursor: pointer;
        @include translateYhoverButton(0.1, 0.2, -1px);
      }
    }
  }
}

.form-checkbox-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 2.5%;
}

.form-checkbox {
  margin-right: 2%;

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    height: 20px;
    width: 20px;
    border: 2px solid black;
    cursor: pointer;
    background: white;
  }
}

.form-checkbox-checked {
  position: absolute;
  font-size: 20px;
  height: 20px;
  width: 20px;
  left: 7px;
  top: -1px;
  pointer-events: none;
}

.form-notification-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 900;
}
