@import 'mixins';
@import 'breakpoints';

.sign-in-page,
.sign-up-page,
.forgot-password-page {
  input,
  select {
    border: 2px solid black;
    outline-color: lightsalmon;
    background: white !important;
  }

  .form {
    margin-top: 6px !important;
  }

  h2,
  h4 {
    display: flex;
    justify-content: center;
  }

  .sign-in-page_form_wrappers,
  .sign-up-page_form_wrappers,
  .parental-consent_form_wrappers {
    display: flex;
    justify-content: center;

    @media (max-width: $largePhone) {
      flex-direction: column;
    }
  }

  .forgot_password_form_wrapper,
  .sign-in-page_manual_sign_in_wrapper,
  .sign-up-page_manual_sign_up_wrapper,
  .sign-up-page_social_sign_up_wrapper {
    display: flex;
    width: 50%;
    padding: 0 25px;

    @media (max-width: $largePhone) {
      padding: 0;
      width: 100%;
    }
  }

  .forgot_password_form_wrapper,
  .sign-in-page_manual_sign_in_wrapper {
    @media (max-width: $smallTablet) {
      padding: 0;
      width: 100%;
    }
  }

  .sign-up-page_social_sign_up_wrapper {
    justify-content: space-between;
    padding-bottom: 0;

    > button {
      margin-top: 15px;
      height: 52px;
      @include flex-center;
      font-size: 16px;
      @include breakPointsFontSize(14px, 14px, 12px, 12px);

      img {
        padding-right: 6px;
        width: 32px;
        height: 32px;
      }

      .microsoft-icon {
        height: 24px;
        width: 24px;
      }
    }

    @media (max-width: $largePhone) {
      padding-bottom: 12px;
    }
  }

  .sign-up-page_sign_up_separator {
    @include flex-center;
    flex-direction: column;

    @media (max-width: $largePhone) {
      margin-top: 25px;
      flex-direction: row;
    }
  }

  .sign-up-page_sign_up_separator-or {
    margin: 20px 0;
    @include flex-center;

    @media (max-width: $largePhone) {
      margin: 0 20px;
    }
  }

  .sign-up-page_sign_up_separator-pre-line {
    display: none;
    background: lightgray;

    @media (max-width: $largePhone) {
      display: flex;
      height: 1px;
      width: 40%;
    }
  }

  .sign-up-page_sign_up_separator-line {
    width: 1px;
    background: lightgray;
    height: 80%;

    @media (max-width: $largePhone) {
      height: 1px;
      width: 40%;
    }
  }

  .sign-up-page-hr {
    margin: 25px auto;
    background: lightgray;
    height: 1px;
    width: 90%;
  }

  .date-picker-wrapper,
  .sign-up-page_terms-and-policy {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    @include breakPointsFontSize(13px, 12.5px, 12px, 12px);
  }

  .date-picker-wrapper {
    margin-bottom: 18px;
    @include breakPointsMarginBottom(16px, 15px, 14px, 12px);

    .form-input-title {
      display: flex;
      align-items: center;
      min-width: fit-content;
      margin-right: 20px;
      padding: 0;
    }

    .date-picker-wrapper-container {
      width: 100%;
      margin-right: 6px;
    }
  }

  .sign-up-page_terms-and-policy-text {
    margin-top: 3px;
    margin-left: 5px;
    margin-bottom: 18px;
    @include breakPointsMarginBottom(16px, 15px, 14px, 12px);

    @media (max-width: $largePhone) {
      margin-top: 5px;
    }
  }

  // TODO - To be deleted when social work
  .sign-up-page_to_be_deleted_when_social_on {
    padding: 0 20%;
    width: 60%;

    @media (max-width: $smallTablet) {
      padding: 0 5%;
      width: 90%;
    }
  }

  .sign-up-page_captcha_wrapper {
    @include flex-center;
    margin-bottom: 25px;
    @include breakPointsMarginBottom(20px, 18px, 25px, 25px);

    iframe {
      border-radius: 108px 29px / 29px 258px;
      border: 2px solid black;
    }
  }

  .sign-up-page_captcha_wrapper-error iframe {
    border: 2px solid red;
  }

  .sign-in-page_forgot-password-link {
    color: lightsalmon;
  }

  .space-between {
    span {
      margin-bottom: -8px;
    }
  }

  .sign-to-other-sign-message {
    padding: 15px 0 5px;
  }

  .sign-to-other-sign-button-wrapper {
    margin: 20px 2px 0;
    @include breakPointsMargin(18px 3px 0, 16px 5px, 2px 3px 12px, 0 2px 10px);
  }

  .forgot-password-page-description {
    margin: 25px auto 0;
    @include breakPointsMarginTop(20px, 18px, 25px, 25px);
    width: 300px;

    @media (max-width: $smallTablet) {
      padding: 0;
      width: 100%;
      max-width: 330px;
    }
  }

  .react-datepicker button {
    margin: 0;
    padding: 0;
    background: none;
    filter: none;
  }

  .react-datepicker__input-container {
    input {
      border-radius: 38px 279px / 279px 38px;
      font-size: 13px;
      padding: 6px 12px;
      width: 75%;
      cursor: pointer;
    }
  }

  .react-datepicker {
    border: none;
    background: none;
  }

  .react-datepicker__header {
    background: wheat;
    border-top-left-radius: 42%;
    border-top-right-radius: 16% !important;
    border-bottom-left-radius: 18%;
    border-bottom-right-radius: 12%;
    border-bottom: none;
  }

  .react-datepicker__month-container {
    background: floralwhite;
    border: 3px solid black;
    border-radius: 8px 79px / 79px 8px;
  }
  .react-datepicker__navigation-icon--previous,
  .react-datepicker__navigation-icon--next {
    filter: invert(1);
  }

  .react-datepicker__day {
    &:not(.react-datepicker__day--selected) {
      &:not(.react-datepicker__day--keyboard-selected) {
        &:hover {
          background: wheat !important;
          border-radius: 208px 29px / 29px 208px !important;
        }
      }
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: lightsalmon !important;
    color: floralwhite !important;
    font-weight: bolder;
    border-radius: 208px 29px / 29px 208px !important;
    border: 2px solid black;
  }

  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    background: none;
    margin-top: 10px !important;

    @media (max-width: $largeTablet) {
      margin-top: 9.5px !important;
    }

    @media (max-width: $smallTablet) {
      margin-top: 9px !important;
    }

    @media (max-width: $largePhone) {
      margin-top: 8.5px !important;
    }

    @media (max-width: $smallPhone) {
      margin-top: 8px !important;
    }

    @media (max-width: $verySmallPhone) {
      margin-top: 7.5px !important;
    }

    &:hover {
      box-shadow: none !important;
    }
  }

  .react-datepicker__month-dropdown-container {
    select {
      font-weight: bolder;
      font-size: 16px;
      border-radius: 208px 29px / 29px 208px !important;
      background: floralwhite;
      padding: 6px 12px;
      @include breakPointsPadding(5px 10px, 4px 8px, 3px 6px, 2px 4px);
    }
  }

  .react-datepicker__year-dropdown-container {
    select {
      font-weight: bolder;
      font-size: 16px;
      border-radius: 28px 159px / 129px 28px !important;
      background: floralwhite;
      padding: 6px 12px;
      @include breakPointsPadding(5px 10px, 4px 8px, 3px 6px, 2px 4px);
    }
  }

  .react-datepicker__input-container {
    input {
      width: -webkit-fill-available;
    }
  }
}

.parental-consent_form_wrappers {
  form {
    margin: 2.5% 0 5%;
  }

  a {
    text-decoration: underline;
  }

  .parental-consent_checkbox-text {
    font-size: 14px;
    @include breakPointsFontSize(13px, 12px, 11px, 10px);
  }

  button {
    background: palegreen !important;
    font-size: 18px;
    @include breakPointsFontSize(16px, 15.5px, 15px, 14.5px, 14px);
  }
}
