@import 'mixins';

$reverseCapitalsBackground: '../../images/backgrounds/geography/reverse-capitals.webp';
$countrySelectBackground: '../../images/backgrounds/geography/country-select.webp';

.geography-page {
  @include set-background('../../images/backgrounds/geography/geography.webp');
}

// if new pages are being added in geography, adding them here might be enough
$geographyPageNames: 'capitals', 'flags', 'locations';

@each $geographyPageName in $geographyPageNames {
  $geographyPageBackground: '../../images/backgrounds/geography/#{$geographyPageName}.webp';

  .#{$geographyPageName}-page,
  .learn-#{$geographyPageName}-page {
    @include set-background($geographyPageBackground);
  }

  .learn-#{$geographyPageName},
  .learn-famous-#{$geographyPageName},
  .learn-other-#{$geographyPageName},
  .easy-#{$geographyPageName}-page,
  .medium-#{$geographyPageName}-page,
  .hard-#{$geographyPageName}-page {
    @include question-answer-game-page;
    @include set-background($geographyPageBackground);
  }
}

.reverse-capitals-page {
  @include set-background($reverseCapitalsBackground);
}

.easy-reverse-capitals-page,
.medium-reverse-capitals-page,
.hard-reverse-capitals-page {
  @include question-answer-game-page;
  @include set-background($reverseCapitalsBackground);
}

.country-select-page {
  @include set-background($reverseCapitalsBackground);
}

.country-map {
  @include svgMapScaling();
}
