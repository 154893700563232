@import 'mixins';

.question-display-color {
  @include squareWidthHeightWithBreakPoints();
  border-radius: 100%;
  @include breakPointsStandardBorders();
}

.learn-display-color {
  @include set-width-height(500px);
  @include breakPointsSetWidthHeight(450px, 400px, 350px, 300px, 200px);
  @include breakPointsStandardBorders();
}

.learn-displayed-image-flags {
  @include breakPointsStandardBorders();
}

.displayed-image-border-radius {
  border-radius: 100%;
}

.displayed-image-geography {
  @include imageDimensionsWithin();
  @include breakPointsStandardBorders();
}

.displayed-image-wrapper-geography {
  @include displayedImageWrapper();
  max-height: 600px;
  width: 90%;
}

.displayed-image-wrapper-words {
  @include displayedImageWrapper();
  aspect-ratio: 1;
  background: white;
  @include breakPointsStandardBorders();
}

.displayed-image-words {
  max-width: 100%;
  max-height: 100%;
}

.learn-displayed-word-image {
  @include imageDimensionsWithin();
}

.flip-image {
  transform: scaleX(-1);
}

.learn-page-stop-button-image {
  @include flex-center;
  @include set-width-height(115px);
  @include breakPointsSetWidthHeight(105px, 95px, 85px, 75px, 65px);
  border: 5px solid black;
  @include breakPointsBorderWidth(4.5px, 4px, 3.5px, 3px, 2px);
  @include translateYhoverButton(0.1, 0.6);
  color: white;
  margin: 20px 0;
  font-family: 'Luckiest Guy';
  letter-spacing: 6px;
  padding-left: 4px;

  background-image: linear-gradient(-180deg, pink 0%, crimson 100%);
  box-shadow:
    0 1rem 1.25rem 0 rgba(255, 25, 85, 0.5),
    0 -0.25rem 1.5rem rgba(255, 15, 15, 1) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(25, 20, 107, 1) inset;

  span {
    color: transparent;
    background-image: linear-gradient(0deg, thistle 0%, #fefafd 100%);
    -webkit-background-clip: text;
    background-clip: text;
    filter: drop-shadow(0 2px 2px hsla(355, 100%, 20%, 1));
    font-size: 36px;
    @include breakPointsFontSize(30px, 26px, 22px, 16px, 12px);

    &:hover {
      background-image: linear-gradient(0deg, ghostwhite 0%, #fefafd 100%);
      filter: drop-shadow(0 2px 2px hsla(355, 100%, 35%, 1));
    }
  }
}
