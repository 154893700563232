@import 'mixins';
@import 'breakpoints';

// DESKTOP
.speech-button-wrapper {
  position: absolute;
  @include full-width-height;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
}

.speech-button-style {
  position: absolute;
  right: 0;
  bottom: 10px;
  @include set-width-height(300px);
  @include breakPointsSetWidthHeight(250px, 200px, 150px, 120px);

  .click-to-hear-animation {
    pointer-events: none;
  }

  .click-to-hear-animation-hand-down {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 999;
    animation: 2s clickDown infinite;
    width: 80%;
    height: 50%;
  }

  .click-to-hear-animation-keyboard {
    position: absolute;
    bottom: 0;
    height: 65%;

    @media (max-width: $largeTablet) {
      width: 100%;
    }
  }

  .click-to-hear-animation-mobile {
    height: 65%;
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
  }

  .click-to-hear-animation-tablet {
    height: 65%;
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
  }

  .click-to-hear-animation-click-sound {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 998;
    animation: 2s clickShow infinite;
    top: 46.5%;
    width: 25%;
    height: 25%;
  }
}

@keyframes clickDown {
  0% {
    top: 0%;
    opacity: 0;
  }
  33% {
    top: 15%;
    opacity: 1;
  }
  100% {
    top: 0%;
    opacity: 0;
  }
}

@keyframes clickShow {
  0% {
    opacity: 0;
  }
  28% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  38% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.show-speech-button-background {
  background: rgba(0, 0, 0, 0.025);
  border-radius: 275px;
}

.speech-button-hide-background {
  background: none;
}

.mobile-device-speech-button {
  @include set-width-height(150px);
  margin-bottom: 20px;
  pointer-events: all;
  cursor: pointer;

  @media (max-width: $largePhone) {
    @include set-width-height(100px);
  }
}

.mobile-device-flag-pulse {
  animation: 1s pulseClick infinite;
}

@keyframes pulseClick {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.mobile-device-flag-disappear {
  animation: flagDisappearing 1.5s forwards;
}

.mobile-device-flag-appears {
  animation: flagAppearing 1.5s;
}

@keyframes flagDisappearing {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes flagAppearing {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
