@import 'mixins';

$divisionsBackground: '../../images/backgrounds/mathematics/divisions.webp';

.mathematics-page {
  @include set-background('../../images/backgrounds/mathematics/mathematics.webp');
}

// if new pages are being added in maths, adding them here might be enough
$mathsPageNames: 'additions', 'subtractions', 'multiplications', 'divisions';

@each $mathsPageName in $mathsPageNames {
  $mathsPageBackground: '../../images/backgrounds/mathematics/#{$mathsPageName}.webp';

  .#{$mathsPageName}-page,
  .learn-#{$mathsPageName}-page,
  .learn-beginner-#{$mathsPageName},
  .learn-easy-#{$mathsPageName},
  .learn-medium-#{$mathsPageName} {
    @include set-background($mathsPageBackground);
  }

  .learn-#{$mathsPageName}-page,
  .learn-beginner-#{$mathsPageName},
  .learn-easy-#{$mathsPageName},
  .learn-medium-#{$mathsPageName},
  .question-answer-game-#{$mathsPageName} {
    @include question-answer-game-page;
    @include set-background($mathsPageBackground);
  }
}

.level-divisions-page,
.beginner-divisions-page,
.easy-divisions-page,
.medium-divisions-page,
.hard-divisions-page {
  @include set-background($divisionsBackground);
}

.hard-additions-number,
.hard-additions-mathematics-sign,
.hard-subtractions-number,
.hard-subtractions-mathematics-sign,
.hard-multiplications-number,
.hard-multiplications-mathematics-sign,
.hard-divisions-number,
.hard-divisions-mathematics-sign {
  height: inherit;
}

// EASY LEVELS
.mathematics-question-display {
  display: flex;
  align-items: center;
}

.number-image {
  height: 100%;
}

.first-numbers,
.second-numbers {
  height: 300px;
  @include breakPointsHeight(250px, 200px, 125px, 80px, 60px);
}

.mathematics-sign {
  height: 175px;
  @include breakPointsHeight(145px, 115px, 70px, 50px, 35px);
  margin: 0 100px;
  @include breakPointsMargin(0 75px, 0 50px, 0 40px, 0 15px);
  margin-bottom: -50px;
  @include breakPointsMarginBottom(-40px, -30px, -20px, -10px);
}

// SUBTRACTON SIGN - SMALLER IN HEIGHT THAN OTHER SIGNS .result-minus is the - for hard, when result is negative
.mathematics-question-display-subtractions .mathematics-sign,
.result-minus {
  height: auto !important;
  max-width: 150px;
  @include breakPointsMaxWidth(125px, 100px, 62.5px, 40px, 30px);
}

// MEDIUM LEVEL - SMALLER FONTS, SIGNS AND MARGINS
.mathematics-question-display-medium,
.mathematics-question-display-hard {
  .first-numbers,
  .second-numbers {
    height: 200px;
    @include breakPointsHeight(175px, 135px, 85px, 55px, 40px);
  }

  .mathematics-sign {
    height: 125px;
    @include breakPointsHeight(100px, 75px, 45px, 30px, 25px);
    margin: 0 60px;
    @include breakPointsMargin(0 50px, 0 35px, 0 20px, 0 15px, 0 10px);
  }
}

// HARD
.mathematics-question-display-hard {
  flex-direction: column;

  .mathematics-sign {
    margin: 60px;
    @include breakPointsMargin(50px, 35px, 20px, 15px, 10px);
  }
}
