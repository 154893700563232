@import 'mixins';
@import 'breakpoints';

.support-page {
  input,
  select,
  textarea {
    border: 2px solid black;
  }

  .navigation-box {
    max-width: 750px;
    @include breakPointsMaxWidth(650px, 500px, 375px, 300px);
    overflow: scroll;
  }

  .paypal-checkout {
    min-height: 210px;
  }

  .support-quote {
    @include flex-center;
    font-size: 24px;
    @include breakPointsFontSize(20px, 18px, 16px, 14px, 12px);
    font-weight: bold;
    font-family: 'Kalam';
  }

  .paypal-checkout-order-message {
    text-align: center;
    line-height: 26px;

    img {
      height: 125px;
    }
  }

  .paypal-checkout-amount-currency {
    display: flex;
    justify-content: center;
    font-family: 'Luckiest Guy';
    margin-top: 36px;
    width: 100%;
    @include breakPointsMarginTop(32px, 28px, 24px, 20px, 16px);
    margin-bottom: 2.5%;

    > input {
      width: 60px;
      @include breakPointsWidth(60px, 50px, 40px, 35px, 30px);
    }
  }

  .input-radio-currency {
    display: grid;
    grid-template-columns: repeat(3, auto);

    .form_field {
      display: flex;
      align-items: center;
      margin-left: 25px;
      @media (max-width: $largePhone) {
        margin-left: 20px;
      }

      @media (max-width: $smallPhone) {
        margin-left: 10px;
      }

      @media (max-width: $verySmallPhone) {
        margin-left: 10px;
      }

      font-size: 16px;
      @include breakPointsFontSize(14px, 12px, 10px, 9px, 8px);

      input {
        margin-right: 5px !important;
      }

      label {
        margin-top: 7px;
        @include breakPointsMarginTop(6px, 6px, 5px, 5px);
      }
    }
  }
  .loading-spinner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Luckiest Guy';
  }

  .support-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .support-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid black;
    border-radius: 50%;
    animation: support-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: black transparent transparent transparent;
  }
  .support-spinner div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .support-spinner div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .support-spinner div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes support-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .progress-bar-container {
    position: relative;
    margin: 35px auto 50px;
    height: 20px;
    width: 100%;
    border-radius: 6px;
    background: lightgrey;
  }

  .progress-bar-bar {
    position: relative;
    @include flex-center;
    height: 100%;
    width: 0;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    background: linear-gradient(
      180deg,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 255, 0, 1) 33%,
      rgba(0, 192, 255, 1) 66%,
      rgba(192, 0, 255, 1) 100%
    );
    transition-property: all;
    transition-duration: 1s;
    transition-timing-function: ease;
  }

  .live-donation-progress-bar {
    margin: 25px auto 50px;
    height: 20px;
  }

  .progress-bar-bar-actual-amount {
    position: absolute;
    font-weight: bold;
    font-size: 12px;
    color: darkgoldenrod;
    @include breakPointsFontSize(12px, 10px, 9px, 8px);
    bottom: 20px;
    opacity: 0;
    transition-property: all;
    transition-duration: 1s;
    transition-timing-function: ease;
  }

  .progress-bar-bar-target {
    position: absolute;
    font-weight: bold;
    right: 0;
    font-size: 13px;
    @include breakPointsFontSize(12px, 12px, 11px, 10px);
    top: 25px;
  }

  .progress-bar-bar-unicorn {
    position: absolute;
    right: -60px;
    bottom: -8px;
    opacity: 0;
    transition-property: all;
    transition-duration: 1s;
    transition-timing-function: ease;
  }

  .support-page-targets {
    @include flex-center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .support-page-targets-target-topic {
    position: absolute;
    top: 50%;
    transform: translate(0, -55%);
    left: 10%;
    max-width: 65%;
    color: white;
    filter: drop-shadow(0 2px 2px black);
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: $largePhone) {
      max-width: 50%;
    }
  }

  .support-page-targets-target-amount {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    color: lightgoldenrodyellow;
    filter: drop-shadow(0 2px 2px black);
    padding-right: 10px;
  }
}

.support-page-targets-target {
  position: relative;
  padding: 20px 100px;
  border-radius: 50px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 600;

  border: 1px solid #012880;
  background-image: linear-gradient(-180deg, yellow 0%, darkorange 100%);
  box-shadow:
    0 1rem 1.25rem 0 lightsalmon,
    0 -0.25rem 1.5rem darkred inset,
    0 0.75rem 0.5rem goldenrod inset,
    0 0.25rem 0.5rem 0 black inset;

  @include breakPointsFontSize(12px, 10px, 9px, 8px, 7.5px);
  @include breakPointsPadding(19px 95px, 15px 75px, 12px 55px, 10px 45px);
}

.support-page-targets-target::before {
  content: '';
  display: block;
  height: 10%;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );

  @media (max-width: $smallTablet) {
    height: 5%;
    top: 10%;
  }
}

// hide scroll bar on support page
.support-page ::-webkit-scrollbar {
  width: 0;
}

// show scroll bar on support page
.support-page-navigation-box ::-webkit-scrollbar {
  width: auto;
}
