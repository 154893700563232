@import 'mixins';
@import 'breakpoints';

.temporarily-unlock-page {
  background-position: bottom;

  .social-share-container {
    margin: 50px 0;
    @include flex-center;

    button {
      margin: 0 30px;
      @include translateYhoverButton(0.1, 0.2, -3px);
      border-radius: 50%;
    }
  }
}
