@import 'mixins';
@import 'breakpoints';

.about-page {
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .video-wrapper {
      width: 85%;
    }

    .content-attribution {
      width: 500px;
      @include breakPointsWidth(450px, 400px, 350px, 250px, 200px);
      margin: 5px 0 25px;
      @include breakPointsMarginBottom(20px, 18px, 15px, 10px, 8px);
      font-size: 14px;
      @include breakPointsFontSize(14px, 13px, 12px, 12px);
      color: darkslategrey;

      a {
        color: darkslategrey;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      @media (max-width: $smallPhone) {
        width: 100%;
      }
    }
  }
}
