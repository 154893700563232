@import 'mixins';
@import 'breakpoints';

.privacy-policy-page,
.terms-of-use-page {
  .navigation-box {
    white-space: pre-line;
  }

  .policy-section,
  .terms-section {
    display: flex;
    flex-direction: column;
    margin: 25px 10px;

    gap: 5px;
  }
}
