@import 'breakpoints';

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin full-width-height {
  width: 100%;
  height: 100%;
}

@mixin set-background($imageUrl) {
  background: url($imageUrl) no-repeat center center;
  background-size: cover;
  @include full-width-height;
}

@mixin question-answer-game-page {
  box-shadow: inset 0 0 0 1000px rgb(255 255 255 / 75%);

  @media (max-width: $largeTablet) {
    padding-top: 25px;
  }

  &.is-playing {
    box-shadow: inset 0 0 0 1000px rgb(255 255 255 / 92%);
  }
}

@mixin set-width-height($dimension) {
  height: $dimension;
  width: $dimension;
}

@mixin absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

@mixin fancy-title-style(
  $fontSize: 36px,
  $backgroundColor: wheat,
  $lettersColor: lightsalmon,
  $borderColor: black,
  $largeTabletDivider: 1.5,
  $smallPhoneDivider: 2
) {
  background: $backgroundColor;
  margin: -30px 0 20px -50px;

  font-family: 'Luckiest Guy';
  @media (min-width: $largeTablet + 1px) {
    font-size: $fontSize;
  }
  @media (max-width: $largeTablet) and (min-width: $smallPhone + 1px) {
    font-size: $fontSize / $largeTabletDivider;
  }
  @media (max-width: $smallPhone) {
    font-size: $fontSize / $smallPhoneDivider;
  }
  color: $lettersColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:
    0px -1px 0 $borderColor,
    0px -1px 0 $borderColor,
    0px 1px 0 $borderColor,
    0px 1px 0 $borderColor,
    -1px 0px 0 $borderColor,
    1px 0px 0 $borderColor,
    -1px 0px 0 $borderColor,
    1px 0px 0 $borderColor,
    -1px -1px 0 $borderColor,
    1px -1px 0 $borderColor,
    -1px 1px 0 $borderColor,
    1px 1px 0 $borderColor,
    -1px 3px 0 $borderColor,
    0px 3px 0 $borderColor,
    1px 3px 0 $borderColor,
    0 4px 1px rgba(0, 0, 0, 0.1),
    0 0 1px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3),
    0 6px 1px rgba(0, 0, 0, 0.2),
    0 3px 3px rgba(0, 0, 0, 0.25),
    0 4px 4px rgba(0, 0, 0, 0.2),
    0 5px 5px rgba(0, 0, 0, 0.15);
}

@mixin create-show-answer-answer-linear-gradient($color) {
  position: absolute;
  top: 0;
  width: 100%;

  @include fancy-title-style(96px, linear-gradient(to right, white, $color, white), black);

  text-shadow: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 0;
}

@mixin create-question-display-text-linear-gradient($color, $fontSize) {
  @include fancy-title-style($fontSize, none, $color);
}

@mixin create-show-clue-color($color) {
  @include fancy-title-style(48px, none, $color);
  margin: 20px 0;
}

@mixin non-annyang-button {
  position: absolute;
  z-index: 1000;
  padding: 8px 18px;
  font-weight: bold;
  background: darkslategrey !important;
  color: ghostwhite;
  filter: drop-shadow(2px 1px 3px darkslategrey);
  top: 10px;
}

@mixin numbersContainer($height, $flexAlign) {
  height: $height;
  margin: 0;

  @media (max-width: $smallPhone) {
    display: flex;
    align-items: $flexAlign;
    justify-content: center;
  }
}

@mixin breakPointsFontSize(
  $largeTabletFontSize,
  $smallTabletFontSize,
  $largePhoneFontSize,
  $smallPhoneFontSize,
  $verySmallPhoneFontSize: false
) {
  @media (max-width: $largeTablet) {
    font-size: $largeTabletFontSize;
  }

  @media (max-width: $smallTablet) {
    font-size: $smallTabletFontSize;
  }

  @media (max-width: $largePhone) {
    font-size: $largePhoneFontSize;
  }

  @media (max-width: $smallPhone) {
    font-size: $smallPhoneFontSize;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneFontSize {
      font-size: $verySmallPhoneFontSize;
    }
  }
}

@mixin breakPointsMargin(
  $largeTabletMargin,
  $smallTabletMargin,
  $largePhoneMargin,
  $smallPhoneMargin,
  $verySmallPhoneMargin: false
) {
  @media (max-width: $largeTablet) {
    margin: $largeTabletMargin;
  }

  @media (max-width: $smallTablet) {
    margin: $smallTabletMargin;
  }

  @media (max-width: $largePhone) {
    margin: $largePhoneMargin;
  }

  @media (max-width: $smallPhone) {
    margin: $smallPhoneMargin;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneMargin {
      margin: $verySmallPhoneMargin;
    }
  }
}

@mixin breakPointsPadding(
  $largeTabletPadding,
  $smallTabletPadding,
  $largePhonePadding,
  $smallPhonePadding,
  $verySmallPhonePadding: false
) {
  @media (max-width: $largeTablet) {
    padding: $largeTabletPadding;
  }

  @media (max-width: $smallTablet) {
    padding: $smallTabletPadding;
  }

  @media (max-width: $largePhone) {
    padding: $largePhonePadding;
  }

  @media (max-width: $smallPhone) {
    padding: $smallPhonePadding;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhonePadding {
      padding: $verySmallPhonePadding;
    }
  }
}

@mixin breakPointsWidth(
  $largeTabletWidth,
  $smallTabletWidth,
  $largePhoneWidth,
  $smallPhoneWidth,
  $verySmallPhoneWidth: false
) {
  @media (max-width: $largeTablet) {
    width: $largeTabletWidth;
  }

  @media (max-width: $smallTablet) {
    width: $smallTabletWidth;
  }

  @media (max-width: $largePhone) {
    width: $largePhoneWidth;
  }

  @media (max-width: $smallPhone) {
    width: $smallPhoneWidth;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneWidth {
      width: $verySmallPhoneWidth;
    }
  }
}

@mixin breakPointsHeight(
  $largeTabletHeight,
  $smallTabletHeight,
  $largePhoneHeight,
  $smallPhoneHeight,
  $verySmallPhoneHeight: false
) {
  @media (max-width: $largeTablet) {
    height: $largeTabletHeight;
  }

  @media (max-width: $smallTablet) {
    height: $smallTabletHeight;
  }

  @media (max-width: $largePhone) {
    height: $largePhoneHeight;
  }

  @media (max-width: $smallPhone) {
    height: $smallPhoneHeight;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhone {
      height: $verySmallPhoneHeight;
    }
  }
}

@mixin breakPointsMaxHeight(
  $largeTabletMaxHeight,
  $smallTabletMaxHeight,
  $largePhoneMaxHeight,
  $smallPhoneMaxHeight,
  $verySmallPhoneMaxHeight: false
) {
  @media (max-width: $largeTablet) {
    height: $largeTabletMaxHeight;
  }

  @media (max-width: $smallTablet) {
    height: $smallTabletMaxHeight;
  }

  @media (max-width: $largePhone) {
    height: $largePhoneMaxHeight;
  }

  @media (max-width: $smallPhone) {
    height: $smallPhoneMaxHeight;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhone {
      height: $verySmallPhoneMaxHeight;
    }
  }
}

@mixin breakPointsMaxWidth(
  $largeTabletMaxWidth,
  $smallTabletMaxWidth,
  $largePhoneMaxWidth,
  $smallPhoneMaxWidth,
  $verySmallPhoneMaxWidth: false
) {
  @media (max-width: $largeTablet) {
    max-width: $largeTabletMaxWidth;
  }

  @media (max-width: $smallTablet) {
    max-width: $smallTabletMaxWidth;
  }

  @media (max-width: $largePhone) {
    max-width: $largePhoneMaxWidth;
  }

  @media (max-width: $smallPhone) {
    max-width: $smallPhoneMaxWidth;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneMaxWidth {
      max-width: $verySmallPhoneMaxWidth;
    }
  }
}

@mixin breakPointsSetWidthHeight(
  $largeTabletSetWidthHeight,
  $smallTabletSetWidthHeight,
  $largePhoneSetWidthHeight,
  $smallPhoneSetWidthHeight,
  $verySmallPhoneSetWidthHeight: false
) {
  @media (max-width: $largeTablet) {
    @include set-width-height($largeTabletSetWidthHeight);
  }

  @media (max-width: $smallTablet) {
    @include set-width-height($smallTabletSetWidthHeight);
  }

  @media (max-width: $largePhone) {
    @include set-width-height($largePhoneSetWidthHeight);
  }

  @media (max-width: $smallPhone) {
    @include set-width-height($smallPhoneSetWidthHeight);
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneSetWidthHeight {
      @include set-width-height($verySmallPhoneSetWidthHeight);
    }
  }
}

@mixin breakPointsBorderWidth(
  $largeTabletBorderWidth,
  $smallTabletBorderWidth,
  $largePhoneBorderWidth,
  $smallPhoneBorderWidth,
  $verySmallPhoneBorderWidth: false
) {
  @media (max-width: $largeTablet) {
    border-width: $largeTabletBorderWidth;
  }

  @media (max-width: $smallTablet) {
    border-width: $smallTabletBorderWidth;
  }

  @media (max-width: $largePhone) {
    border-width: $largePhoneBorderWidth;
  }

  @media (max-width: $smallPhone) {
    border-width: $smallPhoneBorderWidth;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneBorderWidth {
      border-width: $verySmallPhoneBorderWidth;
    }
  }
}

@mixin breakPointsStandardBorders() {
  border: 8px solid;
  @include breakPointsBorderWidth(7px, 6px, 5px, 4px, 3px);
}

@mixin wrongAnswerCrossSize($divider) {
  padding: 5px / $divider;
  @include set-width-height(100px / $divider);

  @media (max-width: $smallTablet) {
    @include set-width-height(50px / $divider);
  }

  @media (max-width: $smallPhone) {
    @include set-width-height(40px / $divider);
  }
}

@mixin breakPointsMarginBottom(
  $largeTabletMarginBottom,
  $smallTabletMarginBottom,
  $largePhoneMarginBottom,
  $smallPhoneMarginBottom,
  $verySmallPhoneMarginBottom: false
) {
  @media (max-width: $largeTablet) {
    margin-bottom: $largeTabletMarginBottom;
  }

  @media (max-width: $smallTablet) {
    margin-bottom: $smallTabletMarginBottom;
  }

  @media (max-width: $largePhone) {
    margin-bottom: $largePhoneMarginBottom;
  }

  @media (max-width: $smallPhone) {
    margin-bottom: $smallPhoneMarginBottom;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneMarginBottom {
      margin-bottom: $verySmallPhoneMarginBottom;
    }
  }
}

@mixin breakPointsMarginTop(
  $largeTabletMarginTop,
  $smallTabletMarginTop,
  $largePhoneMarginTop,
  $smallPhoneMarginTop,
  $verySmallPhoneMarginTop: false
) {
  @media (max-width: $largeTablet) {
    margin-top: $largeTabletMarginTop;
  }

  @media (max-width: $smallTablet) {
    margin-top: $smallTabletMarginTop;
  }

  @media (max-width: $largePhone) {
    margin-top: $largePhoneMarginTop;
  }

  @media (max-width: $smallPhone) {
    margin-top: $smallPhoneMarginTop;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneMarginTop {
      margin-top: $verySmallPhoneMarginTop;
    }
  }
}

@mixin breakPointsLineHeight(
  $largeTabletLineHeight,
  $smallTabletLineHeight,
  $largePhoneLineHeight,
  $smallPhoneLineHeight,
  $verySmallPhoneLineHeight: false
) {
  @media (max-width: $largeTablet) {
    line-height: $largeTabletLineHeight;
  }

  @media (max-width: $smallTablet) {
    line-height: $smallTabletLineHeight;
  }

  @media (max-width: $largePhone) {
    line-height: $largePhoneLineHeight;
  }

  @media (max-width: $smallPhone) {
    line-height: $smallPhoneLineHeight;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneLineHeight {
      line-height: $verySmallPhoneLineHeight;
    }
  }
}

@mixin breakPointsTop(
  $largeTabletTop,
  $smallTabletTop,
  $largePhoneTop,
  $smallPhoneTop,
  $verySmallPhoneTop: false
) {
  @media (max-width: $largeTablet) {
    top: $largeTabletTop;
  }

  @media (max-width: $smallTablet) {
    top: $smallTabletTop;
  }

  @media (max-width: $largePhone) {
    top: $largePhoneTop;
  }

  @media (max-width: $smallPhone) {
    top: $smallPhoneTop;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneTop {
      top: $verySmallPhoneTop;
    }
  }
}

@mixin breakPointsLeft(
  $largeTabletLeft,
  $smallTabletLeft,
  $largePhoneLeft,
  $smallPhoneLeft,
  $verySmallPhoneLeft: false
) {
  @media (max-width: $largeTablet) {
    left: $largeTabletLeft;
  }

  @media (max-width: $smallTablet) {
    left: $smallTabletLeft;
  }

  @media (max-width: $largePhone) {
    left: $largePhoneLeft;
  }

  @media (max-width: $smallPhone) {
    left: $smallPhoneLeft;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhoneLeft {
      left: $verySmallPhoneLeft;
    }
  }
}

@mixin displayedImageWrapper() {
  @include flex-center;
  max-height: 500px;
  height: 100%;
  padding: 25px;
  border-radius: 10%;
  overflow: hidden;

  @media (max-width: $largePhone) {
    height: auto;
    margin: 0 20px;
  }
}

@mixin imageDimensionsWithin() {
  max-width: 100%;
  max-height: 100%;
  @include set-width-height(auto);
}

@mixin makeFadeInOut($animation-name, $size) {
  @keyframes #{$animation-name} {
    0% {
      height: $size;
    }
    10% {
      height: 0.95 * $size;
    }
    20% {
      height: $size;
    }
    30% {
      height: 0.9 * $size;
    }
    40% {
      height: $size;
    }
    50% {
      height: 0.8 * $size;
    }
    60% {
      height: $size;
    }
    70% {
      height: 0.85 * $size;
    }
    80% {
      height: $size;
    }
    90% {
      height: 0.9 * $size;
    }
    100% {
      height: 0.95 * $size;
    }
  }
}

@mixin customNavigationButtonIcon($color) {
  position: absolute;
  background: $color;
  font-size: 10px;
  padding: 2px 4px;
  top: -10px;
  right: -10px;
  border: 1.5px solid black;
  pointer-events: none;
  font-weight: bold;
  @include breakPointsFontSize(9px, 8px, 7px, 6px);
}

@mixin parentsInfoMessage($url) {
  position: absolute;
  top: 0;
  white-space: break-spaces;
  font-weight: bold;
  text-align: center;
  background: url($url) no-repeat center center;
  background-size: contain;
  height: 200px;
  @include flex-center;
  flex-direction: column;
}

@mixin imageChoiceButtonBox() {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: bold;
  min-width: 100px;
}

@mixin squareWidthHeightWithBreakPoints() {
  @include set-width-height(500px);
  @include breakPointsSetWidthHeight(500px, 425px, 375px, 250px, 175px);
}

@mixin translateYhoverButton($opacity: 0.1, $opacityHover: 0.2, $translateY: -3px) {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, $opacity);
  transition: all 0.25s linear;

  &:hover {
    cursor: pointer;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, $opacityHover);
    transform: translateY($translateY);
    opacity: 1;
  }
}

@mixin link-behaviour() {
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}

@mixin svgMapScaling() {
  max-height: 60%;
  max-width: 90%;
  margin-top: 25px;

  svg {
    height: 100%;
    width: 100%;
  }
}

@mixin navigationButtonNeedActionstyle() {
  filter: grayscale(1);

  &:hover {
    .menu-button-icon {
      opacity: 0;
    }
  }
}

@mixin with-ellipsis {
  display: block !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
