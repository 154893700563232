@import 'mixins';
@import 'breakpoints';

.profile-page {
  input,
  select {
    border: 2px solid black;
    outline-color: lightsalmon;
    background: white !important;
  }

  .navigation-box {
    width: 600px;

    @media (max-width: $smallTablet) {
      width: auto;
      max-width: 600px;
    }
  }

  .profile-page-welcome-message {
    text-align: center;
    font-size: 24px;
    @include breakPointsFontSize(20px, 18px, 16px, 14px, 12px);
    font-weight: bold;
    font-family: 'Kalam';
  }

  button {
    font-size: 13px;
    @include breakPointsFontSize(12.5px, 12px, 11px, 10px);
    padding: 8px 18px;
    @include breakPointsPadding(7px 16px, 6px 14px, 5px 12px, 4px 8px);

    opacity: 0.75;
    transition: all 0.25s linear;
    font-weight: bold;

    @media (max-width: $largePhone) {
      opacity: 1;
    }

    &.profile-button-disabled {
      cursor: not-allowed;
      background: lightgray;
    }

    &:not(.profile-button-disabled) {
      &:hover {
        cursor: pointer;
        @include translateYhoverButton(0.1, 0.2, -1px);
      }
    }
  }

  .profile-page-buttons-wrapper,
  .profile-grid {
    button {
      @include with-ellipsis;
    }
  }

  .profile-page-empty-data-button {
    @include flex-center;
    margin-top: 18px;
    @include breakPointsMarginTop(16px, 14px, 12px, 10px, 8px);

    .profile-page-save-button {
      &:not(.profile-page-modal-button) {
        font-size: 18px;
        @include breakPointsFontSize(16px, 15.5px, 15px, 14.5px, 14px);
        font-weight: bold;
      }
    }
  }

  .profile-page-modal-button {
    grid-column: span 5;
    margin: 20px auto 0;
    width: fit-content;
  }

  .profile-page-content-wrapper {
    padding: 10px 50px 25px;
    @include breakPointsPadding(10px 50px 25px, 6px 30px 15px, 4px 20px 10px, 2px 5px 5px, 0 0 5px);

    > div {
      margin: 15px 0;
    }
  }

  .profile-page-field-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .with-transition {
      position: absolute;
    }
  }

  .profile-page-buttons-wrapper {
    justify-content: space-around;

    button {
      &:not(.profile-page-modal-button) {
        width: 30%;

        @media (max-width: $largePhone) {
          width: auto;
        }
      }
    }
  }

  .profile-page-go-button {
    background: palegreen !important;
    font-size: 18px;
    @include breakPointsFontSize(16px, 15.5px, 15px, 14.5px, 14px);
    font-weight: bold;
  }

  .profile-page-save-button {
    background: palegreen !important;
  }

  .profile-page-update-button {
    background: wheat;
  }

  .profile-page-delete-button {
    background: red;
    color: snow;
  }

  .profile-dob {
    margin-left: 3px;
  }

  .profile-page-title-info {
    @include fancy-title-style(24px, unset, gold, black, 1.25, 1.5);
    padding: 35px 0 0 50px;
  }

  .profile-page-title-plan {
    @include fancy-title-style(24px, unset, deeppink, black, 1.25, 1.5);
    padding: 35px 0 0 50px;
    width: 50%;
  }

  .profile-page-title-social {
    @include fancy-title-style(24px, unset, skyblue, black, 1.25, 1.5);
    padding: 35px 0 0 50px;
  }

  .profile-page-title-actions {
    @include fancy-title-style(24px, unset, lawngreen, black, 1.25, 1.5);
    padding: 35px 0 0 50px;
  }

  .profile-page-title-danger-zone {
    @include fancy-title-style(24px, unset, orangered, black, 1.25, 1.5);
    padding: 35px 0 0 50px;
  }

  .profile-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(10, 1fr);
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    > button {
      @include flex-center;
      padding: 8px 18px;
      @include breakPointsPadding(7px 16px, 6px 14px, 5px 12px, 4px 8px);
    }
  }

  $spanSizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

  @each $size in $spanSizes {
    .profile-grid-span-#{$size} {
      grid-column: span $size;
      display: flex;
      align-items: center;
      margin-top: 10px;

      > input {
        width: 100%;
      }
    }
  }

  .profile-grid-span-3,
  .profile-grid-span-8 {
    padding: 0 10px;
  }

  .form-md-select-field {
    width: 100% !important;
  }

  .profile-page-parent-email,
  .profile-page-email-not-confirmed-wrapper {
    @include flex-space-around;
    flex-direction: column;
    padding: 25px 50px 50px;

    button {
      margin-top: 25px;
    }
  }

  .profile-page-parent-email {
    min-height: 250px;
  }

  .profile-value-ellipsis {
    @include with-ellipsis;
  }

  .profile-value-with-margin-right {
    margin-right: 70px;
  }

  .profile-info-section-title {
    width: 40%;
    @include with-ellipsis;
  }

  .profile-info-section-value {
    width: 60%;
  }

  .profile-value {
    font-weight: bold;
    padding: 0 !important;
  }

  .form-field-error {
    margin-top: 2px !important;
  }

  .profile-picture {
    @include absolute-top-right;
    width: 60px;
    height: auto;
    border-radius: 50%;
    border: 3px solid black;
    background: radial-gradient(lightsalmon, transparent);

    @media (max-width: $smallPhone) {
      width: 40px;
    }
  }

  .profile-grid-mobile-2 {
    @media (max-width: $largePhone) {
      grid-column: span 2;
    }
  }

  .profile-grid-mobile-8 {
    @media (max-width: $largePhone) {
      grid-column: span 8;

      > div {
        width: 100%;
      }
    }
  }

  .profile-grid-span-5 {
    @media (max-width: $smallPhone) {
      grid-column: span 10;
    }
  }

  .profile-page-parent-email-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 18px;
    @include breakPointsMarginTop(16px, 14px, 12px, 10px, 8px);
  }

  .profile-page-save-button-disabled {
    &:hover {
      cursor: not-allowed !important;
      box-shadow: none !important;
      transform: none !important;
      opacity: 0.75 !important;
    }
  }
}
