@import 'mixins';
@import 'breakpoints';

.question-answer-game {
  @include flex-center;
  flex-direction: column;
  @include full-width-height;
}

.question-answer-navigation-buttons {
  position: absolute;
  bottom: 0;

  button {
    padding: 10px;
    margin: 10px;
    background: darkslategrey;
    font-weight: bold;
    color: ghostwhite;
  }
}

.learn-page-title,
.question-answer-game-page-title {
  @include fancy-title-style(72px, none, red);
  white-space: break-spaces;
  text-align: center;
  max-width: 900px;
  @include breakPointsMaxWidth(700px, 550px, 480px, 360px);
}

.learn-page-title {
  margin: 20px 0;
}

.annyang-not-supported-show-answer-button {
  @include non-annyang-button;
  right: 10px;
}

.annyang-not-supported-next-question-button {
  @include non-annyang-button;
}

.annyang-not-supported-menu-button {
  @include non-annyang-button;
  left: 10px;
}

.questions-page {
  background: url('../../images/backgrounds/questions-page.webp') repeat center center;

  .jump-to-questions-box {
    background: wheat;

    li {
      margin-bottom: 6px;
      font-weight: 500;
      cursor: pointer;
      width: fit-content;
    }

    // a {
    //   text-decoration: none;
    //   color: black;
    // }
  }

  .jump-to-questions-box-title {
    @include fancy-title-style(24px, wheat, gold, black, 1.25, 1.5);
    padding: 35px 0 20px 80px;
  }

  .question-answer-box {
    background: antiquewhite;
    padding: 20px;
    margin: 50px;
    border: 10px solid black;
    border-radius: 40px;
  }

  .question-answer-box-title {
    @include fancy-title-style(24px, wheat, gold, black, 1.25, 1.5);
    padding: 35px 0 20px 80px;
    position: relative;
  }

  .question-answer-empty-box-color-filler {
    position: absolute;
    left: 100%;
    @include full-width-height;
    background: wheat;
    bottom: 0;
  }

  .questions-page-navigate-to-support {
    text-decoration: underline;
    font-weight: bold;
  }
}

.learn-page {
  @include flex-center;
}
