@import 'mixins';

/* From public does not seem to work anymore */
.language-choice-wrapper {
  @include set-background('../../images/backgrounds/language-choice.webp');
}

$pageNames: 'about', 'achievements', 'admin', 'contact', 'contact-error', 'contact-success',
  'forgot-password', 'hall-of-fame', 'language', 'menu', 'page-not-found', 'parental-consent',
  'profile', 'privacy-policy', 'settings', 'shop', 'sign-in', 'sign-up', 'support',
  'temporarily-unlock', 'terms-of-use';

@each $pageName in $pageNames {
  .#{$pageName}-page {
    @include set-background('../../images/backgrounds/#{$pageName}-page.webp');
  }
}
