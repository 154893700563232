@import 'mixins';
@import 'breakpoints';

$showAnswerColors: aquamarine, blue, blueviolet, brown, burlywood, chocolate, crimson, darkgray,
  darkgreen, darkslateblue, darkturquoise, darkviolet, deeppink, deepskyblue, dodgerblue, firebrick,
  goldenrod, green, greenyellow, hotpink, lavender, lawngreen, lightcyan, lightgreen, lightgrey,
  lightpink, lightskyblue, lightyellow, lime, limegreen, magenta, mediumpurple, mediumslateblue,
  mediumspringgreen, mistyrose, olive, orange, orangered, orchid, papayawhip, pink, powderblue,
  purple, red, sienna, skyblue, slategrey, snow, springgreen, tan, teal, thistle, turquoise, wheat,
  yellow, yellowgreen;

@each $showAnswerColor in $showAnswerColors {
  .show-answer-answer-#{$showAnswerColor} {
    @include create-show-answer-answer-linear-gradient($showAnswerColor);
  }
}

// only 6 pages show clues, capitals & reverse capitals; each for easy/medium/hard so 6 colors needed.
// these 6 are the colours of the titles of these pages. No need for other colours classes
$showClueColors: lawngreen, dodgerblue, greenyellow, limegreen, mediumslateblue, mediumspringgreen;

@each $showClueColor in $showClueColors {
  .show-clue-#{$showClueColor} {
    @include create-show-clue-color($showClueColor);
  }
}

// This is only used in the learn reverse capitals page. It has a custom
// animation instead of using <RandomAnimation was clashing with it
.show-clue-ghostwhite {
  position: absolute;
  @include create-show-clue-color(ghostwhite);
  font-size: 1px;
  filter: drop-shadow(2px 4px 6px black);
  opacity: 0;
  animation: fade 5s infinite;
  text-align: center;

  @media (max-width: $largeTablet) {
    animation: fade128 5s infinite;
  }

  @media (max-width: $smallTablet) {
    animation: fade96 5s infinite;
  }

  @media (max-width: $largePhone) {
    animation: fade74 5s infinite;
  }

  @media (max-width: $smallPhone) {
    animation: fade56 5s infinite;
  }

  @media (max-width: $verySmallPhone) {
    animation: fade36 5s infinite;
  }
}

// nb: Could not use a mixin to do the whole animation - argument didn't work to make @keyframes
@mixin showClueAnimCommonData() {
  30%,
  90% {
    opacity: 0;
  }
  45%,
  75% {
    opacity: 1;
  }
}

@keyframes fade {
  @include showClueAnimCommonData();
  60% {
    font-size: 196px;
  }
}

@keyframes fade128 {
  @include showClueAnimCommonData();
  60% {
    font-size: 128px;
  }
}

@keyframes fade96 {
  @include showClueAnimCommonData();
  60% {
    font-size: 96px;
  }
}

@keyframes fade74 {
  @include showClueAnimCommonData();
  60% {
    font-size: 74px;
  }
}

@keyframes fade56 {
  @include showClueAnimCommonData();
  60% {
    font-size: 56px;
  }
}

@keyframes fade36 {
  @include showClueAnimCommonData();
  60% {
    font-size: 36px;
  }
}

$textColors: aquamarine, goldenrod, green, lavender, lightcyan, lightgrey, lightgreen, lightpink,
  lightskyblue, lightyellow, lime, mistyrose, papayawhip, pink, powderblue, snow, springgreen, tan,
  thistle, wheat, yellow, yellowgreen, blue, blueviolet, brown, burlywood, chocolate, darkgray,
  darkgreen, deeppink, dodgerblue, hotpink, limegreen, magenta, olive, orange, orchid, purple, red,
  skyblue, slategrey, teal, turquoise;

@each $textColor in $textColors {
  .question-display-text-big-#{$textColor} {
    @include create-question-display-text-linear-gradient($textColor, 128px);
  }

  .question-display-text-medium-#{$textColor} {
    @include create-question-display-text-linear-gradient($textColor, 64px);
  }

  .question-display-text-small-#{$textColor} {
    @include create-question-display-text-linear-gradient($textColor, 36px);
  }
}
