@import 'mixins';

.wrong-answer-crosses {
  width: 550px;
  height: 110px;
  display: flex;
  flex-wrap: wrap;
  place-content: flex-start;
  margin-top: 25px;

  @media (max-width: 550px) {
    width: 100%;
  }
}

.wrong-answer-cross {
  @include wrongAnswerCrossSize(1);
}

.small-wrong-answer-cross {
  @include wrongAnswerCrossSize(2);
}

.smaller-wrong-answer-cross {
  @include wrongAnswerCrossSize(4);
}

.long-wrong-answers-list {
  justify-content: center;
}

.number-wrong-answer-crosses {
  display: flex;
  align-items: center;
  height: 110px;
  font-size: 100px;
  font-weight: bold;
  color: crimson;

  > .wrong-answer-cross {
    padding-left: 50px;
  }
}
