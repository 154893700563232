@import 'mixins';
@import 'breakpoints';

.show-answer-wrapper {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
  @include breakPointsHeight(250px, 200px, 125px, 80px, 60px);

  @media (max-width: $largePhone) {
    margin-top: 100px;
  }
}

.show-answer-wrapper-mathematics-beginner,
.show-answer-wrapper-mathematics-easy,
.show-answer-wrapper-mathematics-medium,
.show-answer-wrapper-mathematics-hard {
  flex-direction: row !important;
}

.show-answer-wrapper-mathematics-hard {
  height: 200px !important;

  // not possible to use the breakPointsHeight mixin as I had to override with !important
  @media (max-width: $largeTablet) {
    height: 175px !important;
  }

  @media (max-width: $smallTablet) {
    height: 135px !important;
  }

  @media (max-width: $largePhone) {
    height: 85px !important;
  }

  @media (max-width: $smallPhone) {
    height: 55px !important;
  }

  @media (max-width: $verySmallPhone) {
    @if $verySmallPhone {
      height: 40px !important;
    }
  }
}
