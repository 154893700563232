@import 'mixins';
@import 'breakpoints';

.image-choice-images-wrapper {
  display: flex;
  justify-content: center;
}

.image-choice-button-box {
  @include imageChoiceButtonBox;
  padding: 50px;

  @media (max-width: $smallPhone) {
    padding: 25px 15px;
    flex: 1;
    justify-content: space-between;
  }
}

.image-choice-button-box-small {
  @include imageChoiceButtonBox;
  padding: 5px 25px 15px;

  @media (max-width: $smallPhone) {
    flex: 1;
    justify-content: space-between;
    padding: 3px 8px 4px;
  }
}

.image-choice-button-image {
  max-width: 250px;
  border: 3px solid black;
  margin-top: 15px;
  height: auto;
  @include translateYhoverButton;

  @media (max-width: $smallTablet) {
    width: 90%;
    height: auto;
  }
  @media (max-width: $smallPhone) {
    width: 70%;
  }
}

.image-choice-button-image-small {
  border: 3px solid black;
  width: 100px;
  height: 100px;
  padding: 10px;
  margin-top: 10px;

  @media (max-width: $largePhone) {
    width: 80px;
    height: 80px;
    padding: 8px;
  }
  @media (max-width: $smallPhone) {
    width: 60px;
    height: 60px;
    padding: 6px;
  }
}

.selected-image {
  cursor: not-allowed !important;
}

.not-selected-image {
  cursor: pointer;
  opacity: 0.15;
  @include translateYhoverButton;

  &:hover {
    opacity: 0.5;
  }
}
