@import 'mixins';
@import 'breakpoints';

.view-box-circle-wrapper {
  width: 500px;
  border-radius: 50%;

  circle {
    stroke-width: 31;
    fill: none;
  }

  .outter-color-circle {
    stroke: lightgrey;
  }

  .inner-color-circle {
    stroke: darkgrey;
    stroke-width: 10;
  }

  .tenth {
    stroke-dasharray: 10 100;
  }

  .eleventh {
    stroke-dasharray: 11 100;
  }

  .black-circle {
    stroke: black;
    stroke-dasharray: 2;
  }

  .large-circle {
    stroke-width: 63;
  }

  .middle-circle {
    stroke-width: 42;
  }

  .small-circle {
    fill: white;
    stroke-width: 22;
  }

  .result-area {
    opacity: 0;
  }

  .timer-area {
    stroke-width: 10;
    opacity: 0;
  }

  .number-area {
    stroke-width: 11;
  }

  .one {
    stroke-dashoffset: -80;
  }

  .two {
    stroke-dashoffset: -90;
  }

  .four {
    stroke-dashoffset: -10;
  }

  .five {
    stroke-dashoffset: -20;
  }

  .six {
    stroke-dashoffset: -30;
  }

  .seven {
    stroke-dashoffset: -40;
  }

  .eight {
    stroke-dashoffset: -50;
  }

  .nine {
    stroke-dashoffset: -60;
  }

  .ten {
    stroke-dashoffset: -70;
  }

  @keyframes fadeInHalf {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  @keyframes fadeInFull {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
